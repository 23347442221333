(function () {
    let app = angular.module('clickbooth-common');


    app.directive('tcfButton', function(){
    return {
        priority: 200,
        restrict: 'E',
        scope: {
            tcfId: '@',
            tcfOff: '=',
            tcfOnChange: '&',
        },
        template: require('./table-columns-filter').template,
        controllerAs: 'ctrl',
        controller: require('./table-columns-filter').button_controller,
    }
});

    app.directive('tcfTable',['$timeout', '$rootScope', function($timeout, $rootScope){
    return {
        priority: 0,
        restrict: 'A',
        controllerAs: 'tcf_table_ctrl',
        controller: require('./table-columns-filter').table_controller,
        link: function(scope, element, attrs, ctrl){
            scope.is_tcf_table = true;
            scope.tcf_table_id = attrs.tcfId;
            $rootScope.tcf_init_done = false;

            element.css('visibility', 'hidden');
            element.find('.sortable').hide();

            $timeout(() => {
                element.ready(() => {
                    scope.$apply(() => {
                        ctrl.prepareTCFTable()
                        //$rootScope.$broadcast(`${attrs.tcfId}:table_ready`);
                        ctrl.initTCF();
                    });
                });
            });
        }
    }
}]);

    app.directive('tcfWatchRepeat',['$timeout', '$rootScope', function($timeout, $rootScope){
    return {
        restrict: 'A',
        require: '^tcfTable',
        link: function(scope, element, attrs, ctrl){

            $timeout(() => {
                element.ready(() => {
                    if(scope.$last){
                        scope.$apply(() => {
                            if(ctrl.tcfOnLastRepeat){
                                ctrl.tcfOnLastRepeat(attrs.tcfWatchRepeat)
                            }
                        });
                    }
                    if(scope.$first){
                        scope.$apply(() => {
                            if(ctrl.tcfOnFirstRepeat){
                                ctrl.tcfOnFirstRepeat(attrs.tcfWatchRepe)
                            }
                        });
                    }
                });
            });
        }
    }
}]);

})();