'use strict';

angular.module('clickbooth-common').service('successfullyAddedDevice', ['$rootScope', '$modal', '$state', 'config', function ($rootScope, $modal, $state, config) {
    var scope = $rootScope.$new(true);
    var modal = $modal({
        scope: scope,
        controllerAs: 'ctrl',
        template: '<div class="modal successfully-added-device" tabindex="-1" role="dialog">\n                            <div class="modal-dialog style="max-width: 100%; height: 100%; margin: 0 auto">\n                                <div class="modal-content style="height: 100%"">\n                                    <div class="modal-body">\n                                        <div class="alert-icon success-icon" style="display: flex; align-items: center; justify-content: center;">\n                                            <i class="ti-check" style="font-weight: bold; font-size: 26px; line-height: 1px;"></i>\n                                        </div>\n                    \n                                        <h1 style="color: #3FDE7F">Success!</h1>\n                    \n                                        <p>The new device has been added to your account to <br/> \n                                            use for additional security verification.\n                                        </p>\n                                        \n                                        <span style="display: block; text-align: center; font-size: 12px;">You will automatically be redirected to your <br/> \n                                                dashboard in 3 seconds, if not click \n                                            <a ng-href="{{link}}" id="authoriser">here.</a>\n                                        </span>\n                    \n                                    </div>\n                                </div>\n                            </div>\n                        </div>',
        backdrop: 'static',
        show: false
    });

    return {
        show: function show(options) {
            scope.modalOptions = options;
            modal.$promise.then(modal.show);
        },
        hide: function hide() {
            modal.hide();
        },
        link: function link(url) {
            // console.log(url);
            scope.link = url;
        }
    };
}]);