'use strict';

angular.module('clickbooth-common').service('titleValueModal', ['$rootScope', '$modal', function ($rootScope, $modal) {
	var scope = $rootScope.$new(true);
	var modal = $modal({
		scope: scope,
		templateUrl: template('modals/title-value-modal'),
		backdrop: 'static',
		show: false
	});

	return {
		show: function show(options) {
			if (isUndefinedOrNull(options.jsonSet)) {
				options.jsonSet = false;
			} else {
				options.jsonSet = true;
			}

			scope.modalOptions = options;
			modal.$promise.then(modal.show);
		},
		hide: function hide() {
			modal.hide();
		}
	};
}]);