export class MMRCaseDetailsModalController {
    item = null;
    compliance_populated = false;
    slickCurrentIndex = 0;

    /* @ngInject */
    constructor($scope, config, MMRCases, modalCreativePreview) {
        this.scope = $scope;
        this.config = config;
        this.service = MMRCases;
        this.modalCreativePreview = modalCreativePreview;
        this.compliance_rep_users = [];
        this.approved_agreed = [{
            value: 0,
            name: 'Disabled'
        }, {
            value: 1,
            name: 'Enabled'
        }];

        this.mmr_case_statuses = this.getMMRCaseStatuses();
        this.mmr_case_statuses_array = this.getMMRCaseStatusesArray();
        this.MMR_SUBMISSION_RECEIVED = this.mmr_case_statuses[0].value;
        this.MMR_RESUBMISSION_RECEIVED = this.mmr_case_statuses[1].value;
        this.MMR_PENDING_MARKETER_REVIEW = this.mmr_case_statuses[2].value;
        this.MMR_APPROVED = this.mmr_case_statuses[8].value;
        this.MMR_DENIED_CLOSED = this.mmr_case_statuses[11].value;
        this.MMR_PARTNER_DETAILED_PENDING_ADDITIONAL_INFO = this.mmr_case_statuses[5].value;
        this.MMR_PARTNER_DETAILED_PENDING_MARKETER_REVISIONS = this.mmr_case_statuses[6].value;
        this.MMR_PARTNER_DETAILED_PENDING_COMPLIANCE_REVISIONS = this.mmr_case_statuses[7].value;
        this.MMR_DORMANT = this.mmr_case_statuses[10].value;
        this.MMR_PENDING_MARKETER_REVIEWED = this.mmr_case_statuses[3].value;
        this.MMR_PENDING_MARKETER_DECLINED = this.mmr_case_statuses[4].value;
        this.MMR_APPROVED_AGREED = this.mmr_case_statuses[9].value;

        this.slickConfig = {
            enabled: true,
            infinite: true,
            method: {},
            slidesToShow: 1,
            adaptiveHeight: true,
            prevArrow: '<button class="arrows arrow-left left-arrow-creatives mmr-slider-previous"><i class="ti-angle-left"></button>',
            nextArrow: '<button class="arrows arrow-right right-arrow-creatives mmr-slider-next"><i class="ti-angle-right"></i></button>',
            event: {
                afterChange: (event, slick, currentSlide, nextSlide) => {
                    this.slickCurrentIndex = currentSlide;
                },
                init: (event, slick) => {
                }
            }
        };
        this.edit_controller = true;
        this.decline_request = false;
        this.show_tooltip = false;

        this.lines_checked = false;
        this.url_presells_checked = false;
        this.notes_checked = false;
        this.uploads_checked = false;

        $scope.$watch('controller.mmr_case.lines_url', (n, o) => {
            if(!isUndefinedOrNull(n)) {
                for(let i = 0; i < n.length > 0; i++) {
                    if(isUndefinedOrNull(n[i].changed)) {
                        this.lines_checked = false;
                    } else {
                        this.lines_checked = true;
                    }
                }
            }
        }, true);

        $scope.$watch('controller.mmr_case.url_presells', (n, o) => {
            if(!isUndefinedOrNull(n)) {
                for(let i = 0; i < n.length > 0; i++) {
                    if(isUndefinedOrNull(n[i].changed)) {
                        this.url_presells_checked = false;
                    } else {
                        this.url_presells_checked = true;
                    }
                }
            }
        }, true);

        $scope.$watch('controller.mmr_case.notes', (n, o) => {
            if(!isUndefinedOrNull(n)) {
                for(let i = 0; i < n.length > 0; i++) {
                    if(isUndefinedOrNull(n[i].changed)) {
                        this.notes_checked = false;
                    } else {
                        this.notes_checked = true;
                    }
                }
            }
        }, true);

        $scope.$watch('controller.mmr_case.mmr_creatives', (n, o) => {
            if(!isUndefinedOrNull(n)) {
                for(let i = 0; i < n.length > 0; i++) {
                    if(isUndefinedOrNull(n[i].changed) && new Date(n[i].created_at).getTime() === new Date(n[i].updated_at).getTime()) {
                        this.uploads_checked = false;
                    } else {
                        this.uploads_checked = true;
                    }
                }
            }
        }, true);
    }

    init(mmr_case, user, cb, is_advertiser = false) {
        this.mmr_case = mmr_case;
        this.old_status = this.mmr_case.status;
        this.user = user;
        this.cb = cb;
        this.is_advertiser = is_advertiser;
        if(!isUndefinedOrNull(this.user)) {
            this.complianceRepUsers();


            for (var value of user) {
                if (value.id == this.mmr_case['compliance_operator_id']) {
                    this.compliance_rep = value;
                }
            }
        }

        this.scope.visit_url = !isUndefinedOrNull(this.mmr_case.preview_url) && this.mmr_case.preview_url !== "" ?
            this.mmr_case.preview_url : this.mmr_case.destination_url;

        this.service.getCreatives(mmr_case.id)
            .to(this.mmr_case, 'mmr_creatives')
            .fetching(this, 'fetching_creatives')
            .then((response) => {
                this.mmr_case.mmr_creatives = response;

                if (typeof this.mmr_case.mmr_creatives != 'undefined' && this.mmr_case.mmr_creatives.length > 0) {
                    this.initMMRCreatives();
                }

                setTimeout(function () {
                    window.dispatchEvent(new Event('resize'));
                }, 400);
            })

    }

    approvedAgreed(){
        let values = [];

        angular.forEach(this.approved_agreed, (v, k) =>{
            values.push({
                name: v,
                value: k
            });
        });

        return values;
    }

    getMMRCaseStatuses(){
        let statuses = [];

        angular.forEach(this.config.mmrCaseStatuses, (v, k) =>{
           statuses.push({
               name: v,
               value: k
           });
        });

        return statuses;
    }

    initMMRCreatives() {
        for (let i = 0; i < this.mmr_case.mmr_creatives.length; i++) {
            this.mmr_case.mmr_creatives[i].file = {
                // url: config.api + '/file/mmr_creative/' + this.mmr_case.mmr_creatives[i].id
                url: this.service.getMMRCreativeImage(this.mmr_case.mmr_creatives[i])
            }
        }
    }

    hideModal(hide){
        if (this.cb && !isUndefinedOrNull(this.cb.onHide)){
            this.cb.onHide();
        }
        hide();
    }

    removeCreative(creative, index) {
        for (let i = this.mmr_case.mmr_creatives.length - 1; i >= 0; i--) {
            if (this.mmr_case.mmr_creatives[i].id == creative.id) {
                this.mmr_case.mmr_creatives.splice(i, 1);
            }
        }
        this.slickConfig.method.slickRemove(index)
    }

    canShowSubmit() {
        if(this.config.user.user_type === 'advertiser' && this.notSubmittedAll()) {
            this.show_tooltip = true;
        } else if(this.config.user.user_type === 'admin' && this.mmr_case.status !== this.old_status && this.notSubmittedAll()) {
            this.show_tooltip = true;
        } else {
            this.show_tooltip = false;
        }
    }

    saveMMRCase(hide){
        if(this.config.user.user_type === 'advertiser' && this.mmr_case.status === this.MMR_PENDING_MARKETER_REVIEW) {
            this.mmr_case.status = this.MMR_PENDING_MARKETER_REVIEWED;
        }

        this.service.saveMMRCase(this.mmr_case)
            .then(() => {
                if (this.cb && !isUndefinedOrNull(this.cb.onHide)){
                    this.cb.onHide();
                }

                hide();
            });
    }

    notSubmittedAll() {
        let lines = this.notChanged(this.mmr_case.lines_url);
        let urls = this.notChanged(this.mmr_case.url_presells);
        let notes = this.notChanged(this.mmr_case.notes);
        let creatives = this.notChangedCreatives(this.mmr_case.mmr_creatives);
        return lines || urls || notes || creatives;
    }

    notChanged(data) {
        if(!isUndefinedOrNull(data)) {
            for (let i = 0; i < data.length; i++) {
                if (!isUndefinedOrNull(data[i]['changed']) && data[i]['changed'] !== 1) {
                    return true;
                } else if (isUndefinedOrNull(data[i]['changed'])) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    notChangedCreatives(data) {
        if(!isUndefinedOrNull(data)) {
            for (let i = 0; i < data.length; i++) {
                if (new Date(data[i].created_at).getTime() === new Date(data[i].updated_at).getTime()) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    visitUrl(destination_url) {

        if(!destination_url) {
            return;
        }

        var arr = ['{linkid}', '{clickid}', '{subid1}', '{subid2}', '{subid3}', '{subid4}', '{subid5}', '{subid6}', '{creative_id}'];

        arr.forEach(function (a) {
            var count = a.length;
            var offset = destination_url.indexOf(a);

            if (offset > -1) {
                destination_url = destination_url.substr(0, offset) + destination_url.substr(offset + count);

                //edge case
                if(destination_url.indexOf(a) != -1) {
                    destination_url = destination_url.substr(0, destination_url.length - count);
                }
            }
        });
        return destination_url;
    };

    clickOnImage(item, creative){
        creative.is_preview = !creative.is_preview;

        for(let i = 0; i < item.mmr_creatives.length; i++){
            if(item.mmr_creatives[i].id != creative.id){
                item.mmr_creatives[i].is_preview = false;
            }
        }

        setTimeout(function () {
            $('.mmr-slick-carousel')[0].slick.refresh();
        }, 50);
    }

    complianceRepUsers() {
        if(!this.compliance_populated) {
            this.user.forEach((value) => {
                if (value.approve_sector != null && value.approve_sector['compliance'] == 1) {
                    this.compliance_rep_users.push(value);
                }
            });
            this.compliance_populated = true;
        }
    }

    changeStatus (data, status) {
        for (let i = 0; i < data.length; i++) {
            if((!(data[i].changed_by === 'admin' && data[i].approved === 0) && data[i].changed_by !== 'advertiser'
                || !isUndefinedOrNull(data[i].temp_changed))) {

                data[i].approved = status;
                data[i].changed = 1;
                data[i].temp_changed = 1;
            }
        }
    }

    resetStatus (new_data) {
        for (let i = 0; i < new_data.length; i++) {

            if(new_data.changed_by !== 'advertiser' &&
                (!((new_data[i].changed_by === 'admin' || new_data[i].changed_by === 'advertiser') && new_data[i].approved === 0)
                || !isUndefinedOrNull(new_data[i].temp_changed))) {
                delete new_data[i].changed;
                delete new_data[i].approved;
                delete new_data[i].temp_changed;
            }
        }
    }

    changeStatusCreative (creatives, status) {
        for (let i = 0; i < creatives.length; i++) {
            if(creatives[i].additional_info['changed_by'] !== 'advertiser' &&
                (!(creatives[i].additional_info['changed_by'] === 'admin' && creatives[i].approved === 0) || !isUndefinedOrNull(creatives[i].temp_changed))) {
                creatives[i].approved = status;
                creatives[i].changed = 1;
                creatives[i].temp_changed = 1;
            }
        }
    }

    resetStatusCreative (creatives) {
        for (let i = 0; i < creatives.length; i++) {
            if(!isUndefinedOrNull(creatives[i].temp_changed)) {
                delete creatives[i].changed;
                delete creatives[i].approved;
                delete creatives[i].temp_changed;
            }
        }
    }


    isCheckedApproveCreative (creative) {
        if(creative.approved && this.is_advertiser && (creative.additional_info['changed_by'] !== 'admin' || !isUndefinedOrNull(creative.temp_changed))) {
            return true;
        }

        if(creative.approved && !this.is_advertiser) {
            return true;
        }

        return false;
    }

    isCheckedDeniedCreative (creative) {
        return creative.approved === 0 &&
            ((new Date(creative.created_at).getTime() !== new Date(creative.updated_at).getTime()) || !isUndefinedOrNull(creative.temp_changed));
    }

    isPopulated (data, value) {
        if(isUndefinedOrNull(data)) {
            return false;
        }
        for (let i = 0; i < data.length; i++) {
            if(value === 1) {
                if(!this.is_advertiser && (isUndefinedOrNull(data[i].changed) || data[i].temp_changed) || (data[i].new === 1 && data[i].changed === 1)) {
                    return true;
                }
                if(this.is_advertiser && data[i].new === 1) {
                    return true;
                }
            } else {
                if(!isUndefinedOrNull(data[i].changed)) {
                    if(this.is_advertiser && data[i].new === 0) {
                        return true;
                    }
                    if(!this.is_advertiser) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    showPreview(creative) {
        creative.show_preview = true;

        for (let i = 0; i < this.mmr_case.mmr_creatives.length; i++) {
            if (creative.id != this.mmr_case.mmr_creatives[i].id) {
                this.mmr_case.mmr_creatives[i].show_preview = false;
            }
        }
    }

    closePreview(creative) {
        creative.show_preview = false;
    }

    getMMRCreativeImage (creative, preview = null){
        if(creative.file.id == 0){
            return this.service.getTempMMRCreativeImage(creative, preview);
        }

        return this.service.getMMRCreativeImage(creative, preview);
    }

    showEdit () {
        return true;
    }

    isPopulatedCreatives (creatives, value) {
        if(isUndefinedOrNull(creatives)) {
            return false;
        }

        for (let i = 0; i < creatives.length; i++) {
            if(value) {
                if(new Date(creatives[i].created_at).getTime() === new Date(creatives[i].updated_at).getTime()) {
                    return true;
                }
            } else {
                if(new Date(creatives[i].created_at).getTime() !== new Date(creatives[i].updated_at).getTime()) {
                    return true;
                }
            }

        }
        return false;
    }

    showCreativeFiles(creatives, value) {
        if(isUndefinedOrNull(creatives)) {
            return false;
        }

        for (let i = 0; i < creatives.length; i++) {
            if(value) {
                if((this.config.user.user_type === 'advertiser' && creatives[i].additional_info['changed_by'] !== 'advertiser') ||
                    this.config.user.user_type === 'admin' && creatives[i].additional_info['changed_by'] === 'affiliate') {
                    return true;
                }
            } else {
                if(creatives[i].additional_info['changed_by'] === 'advertiser') {
                    return true;
                }
            }
        }
        return false;
    }

    isNew(creative) {
        return (this.is_advertiser && creative.additional_info['changed_by'] !== 'advertiser') ||
            (!this.is_advertiser && creative.additional_info['changed_by'] === 'affiliate');
    }

    isInitialRequest (data) {
        var flag_affiliate = false;
        var flag_other = false;
        if(isUndefinedOrNull(data)) {
            return false;
        }
        for (let i = 0; i < data.length; i++) {
            if(data[i].changed_by === 'advertiser' && data[i].new === 0) {
                return false;
            }
            if(data[i].changed_by === 'none') {
                flag_affiliate = true;
            }
            if(data[i].changed_by !== 'none') {
                flag_other = true;
            }
        }
        if(flag_affiliate && flag_other) {
            return false;
        }
        return true;
    }

    isInitialRequestCreatives (creatives) {
        if(isUndefinedOrNull(creatives)) {
            return false;
        }
        for (let i = 0; i < creatives.length; i++) {
            if(creatives[i].additional_info['changed_by'] === 'advertiser') {
                return false;
            }
        }
        return true;
    }

    isCheckedApprove (item) {
        //for advertiser
        if(item.approved && item.changed && this.is_advertiser && (item.changed_by !== 'admin' || (!isUndefinedOrNull(item.temp_changed)))) {
            return true;
        }
        //for admin
        if(item.approved && item.changed && !this.is_advertiser) {
            return true;
        }

        return false;
    }

    isCheckedDenied (item) {
        return !item.approved && item.changed;
    }

    openCreativePreview (creative, $index) {
        this.modalCreativePreview.show(creative, $index);
    }

    getMMRCaseStatusesArray() {
        let statuses = [];

        angular.forEach(this.config.mmrCaseStatuses, (v, k) => {
            statuses[k] = v;
        });

        return statuses;
    }

    getPrettyStatus(status_value) {
        return this.mmr_case_statuses_array[status_value];
    }

    getMMRStatusStyle(status_value) {
        let color = 'yellow';
        switch (status_value) {
            case this.MMR_APPROVED:
            case this.MMR_APPROVED_AGREED:
                color = 'green';
                break;
            case this.MMR_DORMANT:
                color = 'orange';
                break;
            case this.MMR_PENDING_MARKETER_REVIEWED:
                color = 'grayblue';
                break;
            case this.MMR_PENDING_MARKETER_DECLINED:
                color = 'grayblue';
                break;
            case this.MMR_PARTNER_DETAILED_PENDING_ADDITIONAL_INFO:
                color = 'grayblue';
                break;
            case this.MMR_PARTNER_DETAILED_PENDING_MARKETER_REVISIONS:
                color = 'grayblue';
                break;
            case this.MMR_PARTNER_DETAILED_PENDING_COMPLIANCE_REVISIONS:
                color = 'grayblue';
                break;
            case this.MMR_PENDING_MARKETER_REVIEW:
                color = 'yellow';
                break;
            case this.MMR_SUBMISSION_RECEIVED:
            case this.MMR_RESUBMISSION_RECEIVED:
                color = 'grayblue';
                break;
            case this.MMR_DENIED_CLOSED:23925
                color = 'red';
        }

        return color;
    }

    declinedBy (item) {
        if(!isUndefinedOrNull(item.temp_changed) && this.is_advertiser) {
            return false;
        }
        if (item.approved === 0 && item.changed === 1) {
            if(this.is_advertiser) {
                if(item.changed_by === 'admin') {
                    return 'Declined by Perform[cb] Compliance';
                } else if (item.changed_by === 'advertiser') {
                    return 'Declined by You';
                }
            } else {
                if(item.changed_by === 'admin') {
                    return 'Declined by Admin';
                } else if (item.changed_by === 'advertiser') {
                    return 'Declined by Marketer';
                }
            }
        } else {
            return false;
        }
    }

    declinedByCreative (creative) {
        if(!isUndefinedOrNull(creative.temp_changed) && this.is_advertiser) {
            return false;
        }
        if (creative.approved === 0
            && (creative.additional_info['changed_by'] === 'admin' || creative.additional_info['changed_by'] === 'advertiser')) {
            if(this.is_advertiser) {
                if(creative.additional_info['changed_by'] === 'admin') {
                    return 'Declined by Perform[cb] Compliance';
                } else if (creative.additional_info['changed_by'] === 'advertiser') {
                    return 'Declined by You';
                }
            } else {
                if(creative.additional_info['changed_by'] === 'admin') {
                    return 'Declined by Admin';
                } else if (creative.additional_info['changed_by'] === 'advertiser') {
                    return 'Declined by Marketer';
                }
            }
        } else {
            return false;
        }
    }

    getChangedAtCreative (creative) {
        return !isUndefinedOrNull(creative.additional_info) && !isUndefinedOrNull(creative.additional_info['changed_at']);
    }

    getChangedAt (item) {
        return !isUndefinedOrNull(item.changed_at);
    }
}
