app = angular.module 'interchange', []

resizing = null
$(window).resize ->
	resizing = setTimeout ->
		$(document).trigger('redraw')
	, 100

app.run ['$rootScope', (scope) ->
	for event in ['$routeChangeSuccess', '$routeChangeError', '$locationChangeSuccess', '$locationChangeError']
		scope.$on event, ->
			scope.$evalAsync ->
				$(document).trigger('redraw')
]

app.provider '$interchangeProvider', ->
	$get: ->
		sm: 768
		md: 992
		lg: 1200


app.directive 'interchange', ($http, $q, $compile, $interchangeProvider, $templateCache) ->
	restrict: 'A'
	link: (scope, elem, attrs) ->
		attribute = ''
		parse = ->
			options = scope.$eval attrs.interchange
			windowWidth = $(window).width()
			prop = 'xs'

			for viewport, width of $interchangeProvider
				if windowWidth >= width and options[viewport]
					prop = viewport

			if options[prop]
				fetchTemplate options[prop]

		attrs.$observe 'interchange', parse

		$(document).on 'redraw', parse

		oldTemplate = ''
		fetchTemplate = (temp) ->
			return if temp is oldTemplate

			$q((resolve) ->
				if $templateCache.get(temp)
					resolve $templateCache.get(temp)
				else
					$http.get(temp).success resolve
			)
			.then (data) ->
				oldTemplate = temp
				$templateCache.put(temp, data)
				elem.children().each -> $(this).remove()
				elem.append $compile(data)(scope)
				$(document).trigger('redraw')
