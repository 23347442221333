'use strict';

angular.module('clickbooth-common').service('editNotificationModal', ['$rootScope', '$modal', 'Users', 'CountryState', function ($rootScope, $modal, Users, CountryState) {
    var globalTextIndex = 0;
    return {
        stringToInsert: "",
        carretPosition: null,
        model: null,
        getGlobalTextIndex: function getGlobalTextIndex() {
            return globalTextIndex;
        },
        show: function show(params) {
            var scope = $rootScope.$new(true);
            scope.limitTo = $rootScope.limitTo;

            var modal = this.modal = $modal({
                scope: scope,
                templateUrl: template('modals/edit-notification-modal'),
                backdrop: 'static',
                show: false
            });

            scope.params = params;
            scope.countries = [];

            CountryState.query().then(function (results) {
                angular.forEach(results.countries, function (item) {
                    scope.countries.push(item);
                });

                if (scope.params.value.excluded_countries.length > 0) {
                    var _loop = function _loop(i) {
                        var country = scope.countries.find(function (x) {
                            if (x.code == scope.params.value.excluded_countries[i]) {
                                return x;
                            }
                        });
                        if (country) {
                            scope.params.value.excluded_countries[i] = country;
                        }
                    };

                    for (var i = 0; i < scope.params.value.excluded_countries.length; i++) {
                        _loop(i);
                    }
                }
            });

            globalTextIndex++;
            scope.textIndex = globalTextIndex;

            scope.cursor = {};
            var admins = {
                user_type: 'admin', paginate_by: 10000000
            };
            Users.where(admins).fetching(scope, 'fetching').then(function (response) {
                scope.admin_users = response.data;
            });
            var all_users = {
                user_type: 'advertiser', paginate_by: 1000000
            };
            Users.where(all_users).fetching(scope, 'fetching').then(function (response) {
                scope.all_users = response.data;
            });
            modal.$promise.then(modal.show);
        },
        hide: function hide(notification) {
            this.modal.hide();
        }
    };
}]).directive('cursor', ['editNotificationModal', function () {
    return {
        restrict: "A",
        insertString: "defaultString",
        link: function link(scope, elem, attr) {
            elem.on('select mouseup touchend keyup click', function (e) {
                var sel;
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {}

                scope.cursor.model = attr.ngModel.substr(13); //'notification_text.text';

                if (scope.cursor.model === 'subject') {
                    scope.cursor.start = elem[0].selectionStart;
                    scope.cursor.end = elem[0].selectionStart;
                    scope.cursor.aim = elem;
                } else {
                    scope.cursor.start = sel.getRangeAt(0)['startOffset'];
                    scope.cursor.end = sel.getRangeAt(0)['startOffset'];
                    scope.cursor.aim = sel.getRangeAt(0);
                }

                //scope.$apply();
            });
        }
    };
}]).directive('insertText', ['$timeout', 'editNotificationModal', 'textAngularManager', function ($timeout, editNotificationModal, textAngularManager) {
    return {
        restrict: "A",
        link: function link(scope, elem, attr) {
            elem.on('click', function (e) {
                scope.service = editNotificationModal;
                scope.cursorAim = scope.service.carretPosition;
                scope.textToInsert = scope.service.stringToInsert;

                if (scope.service.model !== null && scope.service.model === 'subject') {
                    var text = scope.cursorAim.val();
                    var position = scope.cursorAim[0].selectionStart;

                    scope.cursorAim.val([text.slice(0, position), ' ' + scope.textToInsert + ' ', text.slice(position)].join(''));

                    scope.cursorAim.trigger('change');
                } else {
                    scope.textAngularService = textAngularManager;
                    scope.cursorAim.insertNode(document.createTextNode(' ' + scope.textToInsert + ' '));
                    textAngularManager.refreshEditor('notification-text-angular' + scope.service.getGlobalTextIndex());
                }
            });
        }
    };
}]);