'use strict';

// Generated by CoffeeScript 1.9.3
(function () {
	var copyInput, copyNode, copyText, createNode, handleOnce, isFormInput;

	createNode = function createNode(text) {
		var node;
		node = document.createElement('textarea');
		node.style.position = 'absolute';
		node.style.left = '-10000px';
		node.textContent = text.trim();
		return node;
	};

	copyNode = function copyNode(node) {
		var range, selection;
		selection = getSelection();
		selection.removeAllRanges();
		range = document.createRange();
		range.selectNodeContents(node);
		selection.addRange(range);
		document.execCommand('copy');
		return selection.removeAllRanges();
	};

	copyText = function copyText(text) {
		var node;
		node = createNode(text);
		document.body.appendChild(node);
		copyInput(node);
		return document.body.removeChild(node);
	};

	copyInput = function copyInput(node) {
		node.select();
		document.execCommand('copy');
		return getSelection().removeAllRanges();
	};

	handleOnce = function handleOnce(element, eventName, fn) {
		var _handler;
		return element.addEventListener(eventName, _handler = function handler() {
			element.removeEventListener(eventName, _handler);
			return fn.apply(this, arguments);
		});
	};

	$(document).on('click', '.js-zeroclipboard', function (event) {

		var container, content, hint, original, text;
		if (text = this.getAttribute('data-clipboard-text')) {
			copyText(text);
		}

		this.blur();
	});

	isFormInput = function isFormInput(element) {
		return element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA';
	};
}).call(undefined);