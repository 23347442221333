(function() {
	var app;

	app = angular.module('field.validations', []);

	app.run(['$locale', function($locale) {
		$locale.VALIDATIONS = {};
		return angular.extend($locale.VALIDATIONS, {
			required: " The :attribute field is required.",
			minlength: " Must be at least :length characters.",
			maxlength: " This field can be at most :length characters long",
			maxrows: " This field can have a maximum of :length lines",
			email: " Must be a valid email.",
			url: " Must be a valid url.",
			ip: " Wrong ip on line :line: :ip (:message)",
			number: " Must be a valid number.",
			integer: " Must be an integer.",
			min: " Invalid minimum value.",
			max: " Invalid maximum value.",
			date: " Must be a valid date.",
			custom: {
				'custom-field': {
					required: 'Write down the :attribute'
				}
			},
			attributes: {
				'custom-field': 'Captcha'
			}
		});
	}]);

	app.directive('validate', [
		"$compile", "$locale", function($compile, $locale) {
			return {
				require: 'ngModel',
				link: function(scope, elem, attrs, ngModel) {
					var args, delay, e, errorsFromElem, formController, formGroup, formGroupClass, hash, i, innerScope, len, message, name, parentForm, part, parts, processErrors, ref, ref1, template, validators;
					innerScope = scope.$new(true);
					innerScope.ngModel = ngModel;
					message = function(validation, vars) {
						var VALIDATIONS, friendly, k, messageText, regex, v;
						VALIDATIONS = $locale.VALIDATIONS;
						friendly = VALIDATIONS.attributes[vars.name] || (function() {
							if (vars.name && vars.name.length > 0) {
								return vars.name.replace(/_/g, ' ');
							}
							return vars.name;
						})();
						if (VALIDATIONS.custom[vars.name] && VALIDATIONS.custom[vars.name][validation]) {
							messageText = VALIDATIONS.custom[vars.name][validation];
						} else {
							messageText = VALIDATIONS[validation];
						}
						vars.attribute = friendly;
						for (k in vars) {
							v = vars[k];
							regex = new RegExp('\:' + k + '', 'g');
							messageText = messageText.replace(regex, v);
						}
						return messageText;
					};
					parts = attrs.validate.split("|");
					if (attrs.hasOwnProperty('name')) {
						errorsFromElem = elem.parents('[errors-from]:first');
						if (errorsFromElem.length > 0) {
							if ((ref = errorsFromElem.scope()) != null) {
								ref.$watch(errorsFromElem.attr('errors-from'), function(n) {
									ngModel.$validate();
									return processErrors(ngModel.$error);
								}, true);
							}
							parts.push('error_object');
						}
					}
					validators = {
						required: {
							msg: function() {
								return message('required', {
									name: attrs.name
								});
							},
							test: function() {
								return function(modelValue, viewValue) {
									var value;
									value = modelValue || viewValue;
									if (!value) {
										ngModel.$error.required = true;
										return false;
									}
									delete ngModel.$error.required;
									return true;
								};
							}
						},
						email: {
							msg: function() {
								return message('email', {
									name: attrs.name
								});
							},
							test: function() {
								return function(modelValue, viewValue) {
									var regex, value;
									value = modelValue || viewValue;
									if (!value) {
										return true;
									}
									regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i;
									return regex.test(value);
								};
							}
						},
						number: {
							msg: function() {
								return message('number', {
									name: attrs.name
								});
							}
						},
						integer: {
							msg: function() {
								return message('integer', {
									name: attrs.name
								});
							},
							test: function() {
								return function(modelValue, viewValue) {
									var regex, value;
									value = modelValue || viewValue;
									if (!value) {
										return true;
									}
									regex = /^\d+$/;
									return regex.test(value);
								};
							}
						},
						min: {
							msg: function() {
								return message('min', {
									name: attrs.name
								});
							}
						},
						max: {
							msg: function() {
								return message('max', {
									name: attrs.name
								});
							}
						},
						date: {
							msg: function() {
								return message('date', {
									name: attrs.name
								});
							}
						},
						url: {
							msg: function() {
								return message('url', {
									name: attrs.name
								});
							},
							test: function() {
								return function(modelValue, viewValue) {
									var regex, value;
									value = modelValue || viewValue;
									if (!value) {
										return true;
									}

									regex = new RegExp(
										"^" +
										// protocol identifier
										"(?:(?:https?|ftp)://)" +
										// user:pass authentication
										"(?:\\S+(?::\\S*)?@)?" +
										"(?:" +
										// IP address exclusion
										// private & local networks
										"(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
										"(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
										"(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
										// IP address dotted notation octets
										// excludes loopback network 0.0.0.0
										// excludes reserved space >= 224.0.0.0
										// excludes network & broacast addresses
										// (first & last IP address of each class)
										"(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
										"(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
										"(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
										"|" +
										// host name
										"(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
										// domain name
										"(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
										// TLD identifier
										"(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
										// TLD may end with dot
										"\\.?" +
										")" +
										// port number
										"(?::\\d{2,5})?" +
										// resource path
										"(?:[/?#]\\S*)?" +
										"$", "i"
									);

									return regex.test(value);
								};
							}
						},
                        ip: {
                            err: [],
                            msg: function() {
								var err_msg = [];
                                for (var i = 0; i < this.err.length; i++) {
                                    err_msg.push(message('ip', {
                                        name: attrs.name,
										ip: this.err[i].ip,
										message: this.err[i].message,
										line: this.err[i].line + 1
                                    }));
                                }

                                return err_msg;
                            },
                            test: function() {
                            	var _self = this;
                                return function(modelValue, viewValue) {
                                    var value, tmp = [];
                                    value = modelValue /*|| viewValue*/;

                                    if (!value) {
                                        return true;
                                    }

                                    _self.err = [];

                                    var ip_regex = new RegExp(
                                        "^(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
                                        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
                                        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
                                        "$", "i");
                                    var ip_range_regex = new RegExp("^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)((\-)" +
										"(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?\.){3}" +
										"((25[0-4]|2[0-4][0-9]|[01]?[1-9][0-9]?)((\-)" +
										"(25[0-4]|2[0-4][0-9]|[01]?[1-9][0-9]?))?)$", "i");
                                    var ip_mask_regex = new RegExp("^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)" +
										"((\-)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?\.){3}" +
										"((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)((\-)" +
										"(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?)" +
										"(\/(([0-9])|(([1-2][0-9])|(3[0-2]))))$", "i");

                                    for (var i = 0; i < value.length; i++) {
                                    	var ip_test = ip_regex.test(value[i]);
                                    	var ip_range_test = ip_range_regex.test(value[i]);
                                    	var ip_mask_test = ip_mask_regex.test(value[i]);
										var ip_dot_test =  value[i].split('.').length !== 4

										if ((ip_test === false && ip_range_test === false && ip_mask_test === false) || ip_dot_test === true) {
                                            _self.err.push({
                                                ip: value[i],
                                                message: 'Invalid IP format',
												line: i
                                            });
                                        } else if(tmp.indexOf(value[i]) > -1) {
                                            _self.err.push({
                                                ip: value[i],
                                                message: 'Duplicated IP',
                                                line: i
                                            });
                                        }

                                        tmp.push(value[i]);
                                    }

                                    if(_self.err.length) {
										if (typeof ngModel.$error.ip != 'undefined') {
											scope.$evalAsync(function () {
												processErrors(ngModel.$error);
											});
										}

                                    	return false;
									}

                                    return true;
                                };
                            }
                        },
						minlength: {
							msg: function(length) {
								return message('minlength', {
									name: attrs.name,
									length: length
								});
							},
							test: function(length) {
								return function(modelValue, viewValue) {
									var value;
									value = modelValue || viewValue;
									if (value && (value != null ? value.length : void 0) < length) {
										ngModel.$error.minlength = length;
										return false;
									}
									return true;
								};
							}
						},
						maxlength: {
							msg: function(length) {
								return message('maxlength', {
									name: attrs.name,
									length: length
								});
							},
							test: function(length) {
								return function(modelValue, viewValue) {
									var value;
									value = modelValue || viewValue;
									if (value && (value != null ? value.length : void 0) > length) {
										ngModel.$error.maxlength = length;
										return false;
									}
									return true;
								};
							}
						},
                        maxrows: {
                            msg: function(length) {
                                return message('maxrows', {
                                    name: attrs.name,
                                    length: length
                                });
                            },
                            test: function(length) {
                                return function(modelValue, viewValue) {
                                    var value;
                                    value = modelValue || viewValue;
                                    if (value && (value != null ? value.split(/\r\n|\r|\n/).length : void 0) > length) {
                                        ngModel.$error.maxrows = length;
                                        return false;
                                    }
                                    return true;
                                };
                            }
                        },
						error_object: {
							fetchObject: function() {
								var ref1;
								return ((ref1 = errorsFromElem.scope()) != null ? ref1.$eval(errorsFromElem.attr('errors-from')) : void 0) || {};
							},
							msg: function() {
								var errorsObject, propertyError;
								errorsObject = this.fetchObject();
								propertyError = errorsObject[attrs.name];
								if (angular.isArray(propertyError)) {
									return propertyError.join(" ");
								}
								return propertyError;
							},
							test: function() {
								return (function(_this) {
									return function(modelValue, viewValue) {
										var errorsObject, propertyError;
										errorsObject = _this.fetchObject();
										propertyError = errorsObject[attrs.name];
										if (propertyError && propertyError.length > 0) {
											scope.$evalAsync(function() {
												return innerScope.$showMessage = true;
											});
											setTimeout(function() {
												return typeof parentForm !== "undefined" && parentForm !== null ? typeof parentForm.trigger === "function" ? parentForm.trigger('formTouched') : void 0 : void 0;
											});
											return false;
											// innerScope.$showMessage = false;
										}
										return true;
									};
								})(this);
							}
						}
					};
					hash = {};
					for (i = 0, len = parts.length; i < len; i++) {
						part = parts[i];
						ref1 = part.split(':'), name = ref1[0], args = ref1[1];
						hash[name] = args;
						if (validators[name]) {
							if(name == 'ip') {
                                ngModel.$parsers.push(function (value) {
                                    return (value !== "") ? value.split(/\r\n|\r|\n/) : value;
                                });
                                ngModel.$formatters.push(function (value) {
                                    return angular.isArray(value) ? value.join("\n") : value;
                                });
							}
							ngModel.$validators[name] = validators[name].test(args);
						}
					}
					formGroup = elem.parents('.form-group:first');
					formGroupClass = function() {
						return setTimeout(function() {
							var method;
							if (innerScope.$showMessage) {
								method = $.isEmptyObject(ngModel.$error) ? 'removeClass' : 'addClass';
								return formGroup[method]('has-error');
							}
						});
					};
					innerScope.$watch('$showMessage', function(n) {
						return formGroupClass();
					});
					scope.$watch(function() {
						return ngModel.$touched;
					}, function(n, o) {
						if (n) {
							return innerScope.$showMessage = true;
						}
					});
					parentForm = elem.parents('form');
					formController = parentForm.data('$formController');
					parentForm.on('formTouched', function() {
						innerScope.$showMessage = true;
						if (!innerScope.$$phase) {
							return innerScope.$apply();
						}
					});
					if (formController) {
						scope.$watch(function() {
							return formController.$submitted;
						}, function(n, o) {
							if (n) {
								return innerScope.$showMessage = false;
							}
						});
					}
					scope.$watch(attrs.ngModel, function(n) {
						if (formController && formController.$submitted) {
							if (n) {
								return scope.$showMessage = true;
							}
						}
					});
					processErrors = function(n) {
						var m;
						m = [];
						for (name in n) {
							args = hash[name];
							if (name === 'required') {
								continue;
							}
							if (name === 'error_object') {
								continue;
							}
							m.push(validators[name].msg(args));
						}
						if (n.required) {
							m.push(validators.required.msg());
						}
						innerScope.$message = m.join('');
						if (n.error_object) {
							innerScope.$message = validators.error_object.msg();
						}
						return formGroupClass();
					};
					scope.$watch(function() {
						return ngModel.$error;
					}, processErrors, true);
					template = "<div class=\"help-block errors-message\" ng-show=\"$showMessage && $message\">\n  {{$message}}\n</div>";
					delay = null;
					elem.on('keydown keyup click', function(e) {
						if (!ngModel.$touched) {
							return setTimeout(function() {
								parentForm.trigger('formTouched');
								ngModel.$touched = true;
								return scope.$apply();
							}, 500);
						}
					});
					e = $compile(template)(innerScope);
					return e.insertAfter(elem);
				}
			};
		}
	]);
}).call(this);
