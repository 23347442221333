'use strict';

(function () {
	var app = angular.module('clickbooth-common');

	app.directive('inlineDatepicker', ['$window', '$parse', '$q', '$dateFormatter', '$dateParser', '$datepicker', '$timeout', '$animate', function ($window, $parse, $q, $dateFormatter, $dateParser, $datepicker, $timeout, $animate) {

		var defaults = $datepicker.defaults;
		var isNative = /(ip(a|o)d|iphone|android)/ig.test($window.navigator.userAgent);

		return {
			restrict: 'EAC',
			require: 'ngModel',
			link: function postLink(scope, element, attr, controller) {

				scope.$evalAsync(function () {
					$timeout(function () {
						build();
					});
				});

				function build() {

					// Directive options
					var options = {
						scope: scope
						// controller: controller
					};
					angular.forEach(['placement', 'container', 'delay', 'trigger', 'keyboard', 'html', 'animation', 'template', 'autoclose', 'dateType', 'dateFormat', 'modelDateFormat', 'dayFormat', 'strictFormat', 'startWeek', 'startDate', 'useNative', 'lang', 'startView', 'minView', 'iconLeft', 'iconRight', 'daysOfWeekDisabled', 'id'], function (key) {
						if (angular.isDefined(attr[key])) options[key] = attr[key];
					});

					// Visibility binding support
					attr.bsShow && scope.$watch(attr.bsShow, function (newValue, oldValue) {
						if (!datepicker || !angular.isDefined(newValue)) return;
						if (angular.isString(newValue)) newValue = !!newValue.match(/true|,?(datepicker),?/i);
						newValue === true ? datepicker.show() : datepicker.hide();
					});

					angular.extend(options, {
						// animation: 'am-fade',
						// prefixClass: 'datepicker',
						placement: 'bottom-left',
						// template: 'datepicker/datepicker.tpl.html',
						trigger: 'manual',
						// container: false,
						onShow: function onShow() {
							element.next().css({
								top: 'auto',
								left: 'auto'
							});
						},
						// keyboard: true,
						// html: false,
						// delay: 0,
						// // lang: $locale.id,
						// useNative: false,
						// dateType: 'date',
						// dateFormat: 'shortDate',
						// modelDateFormat: null,
						// dayFormat: 'dd',
						// monthFormat: 'MMM',
						// yearFormat: 'yyyy',
						// monthTitleFormat: 'MMMM yyyy',
						// yearTitleFormat: 'yyyy',
						// strictFormat: false,
						// autoclose: false,
						// minDate: -Infinity,
						// maxDate: +Infinity,
						// startView: 0,
						// minView: 0,
						startWeek: 1,
						show: true
						// daysOfWeekDisabled: '',
						// iconLeft: 'glyphicon glyphicon-chevron-left',
						// iconRight: 'glyphicon glyphicon-chevron-right'
					});

					// Initialize datepicker
					var datepicker = $datepicker(element, controller, options);
					options = datepicker.$options;

					// Set expected iOS format
					if (isNative && options.useNative) options.dateFormat = 'yyyy-MM-dd';

					var lang = options.lang;

					var formatDate = function formatDate(date, format) {
						return $dateFormatter.formatDate(date, format, lang);
					};

					var dateParser = $dateParser({ format: options.dateFormat, lang: lang, strict: options.strictFormat });

					// Observe attributes for changes
					angular.forEach(['minDate', 'maxDate'], function (key) {
						// console.warn('attr.$observe(%s)', key, attr[key]);
						angular.isDefined(attr[key]) && attr.$observe(key, function (newValue) {
							// console.warn('attr.$observe(%s)=%o', key, newValue);
							datepicker.$options[key] = dateParser.getDateForAttribute(key, newValue);
							// Build only if dirty
							!isNaN(datepicker.$options[key]) && datepicker.$build(false);
							validateAgainstMinMaxDate(controller.$dateValue);
						});
					});

					// Watch model for changes
					scope.$watch(attr.ngModel, function (newValue, oldValue) {
						datepicker.update(controller.$dateValue);
					}, true);

					// Normalize undefined/null/empty array,
					// so that we don't treat changing from undefined->null as a change.
					function normalizeDateRanges(ranges) {
						if (!ranges || !ranges.length) return null;
						return ranges;
					}

					if (angular.isDefined(attr.disabledDates)) {
						scope.$watch(attr.disabledDates, function (disabledRanges, previousValue) {
							disabledRanges = normalizeDateRanges(disabledRanges);
							previousValue = normalizeDateRanges(previousValue);

							if (disabledRanges) {
								datepicker.updateDisabledDates(disabledRanges);
							}
						});
					}

					function validateAgainstMinMaxDate(parsedDate) {
						if (!angular.isDate(parsedDate)) return;
						var isMinValid = isNaN(datepicker.$options.minDate) || parsedDate.getTime() >= datepicker.$options.minDate;
						var isMaxValid = isNaN(datepicker.$options.maxDate) || parsedDate.getTime() <= datepicker.$options.maxDate;
						var isValid = isMinValid && isMaxValid;
						controller.$setValidity('date', isValid);
						controller.$setValidity('min', isMinValid);
						controller.$setValidity('max', isMaxValid);
						// Only update the model when we have a valid date
						if (isValid) controller.$dateValue = parsedDate;
					}

					// viewValue -> $parsers -> modelValue
					controller.$parsers.unshift(function (viewValue) {
						// console.warn('$parser("%s"): viewValue=%o', element.attr('ng-model'), viewValue);
						// Null values should correctly reset the model value & validity
						if (!viewValue) {
							controller.$setValidity('date', true);
							// BREAKING CHANGE:
							// return null (not undefined) when input value is empty, so angularjs 1.3
							// ngModelController can go ahead and run validators, like ngRequired
							return null;
						}
						var parsedDate = dateParser.parse(viewValue, controller.$dateValue);
						if (!parsedDate || isNaN(parsedDate.getTime())) {
							controller.$setValidity('date', false);
							// return undefined, causes ngModelController to
							// invalidate model value
							return;
						} else {
							validateAgainstMinMaxDate(parsedDate);
						}
						if (options.dateType === 'string') {
							return formatDate(parsedDate, options.modelDateFormat || options.dateFormat);
						} else if (options.dateType === 'number') {
							return controller.$dateValue.getTime();
						} else if (options.dateType === 'unix') {
							return controller.$dateValue.getTime() / 1000;
						} else if (options.dateType === 'iso') {
							return controller.$dateValue.toISOString();
						} else {
							return new Date(controller.$dateValue);
						}
					});

					// modelValue -> $formatters -> viewValue
					controller.$formatters.push(function (modelValue) {
						// console.warn('$formatter("%s"): modelValue=%o (%o)', element.attr('ng-model'), modelValue, typeof modelValue);
						var date;
						if (angular.isUndefined(modelValue) || modelValue === null) {
							date = NaN;
						} else if (angular.isDate(modelValue)) {
							date = modelValue;
						} else if (options.dateType === 'string') {
							date = dateParser.parse(modelValue, null, options.modelDateFormat);
						} else if (options.dateType === 'unix') {
							date = new Date(modelValue * 1000);
						} else {
							date = new Date(modelValue);
						}
						// Setup default value?
						// if(isNaN(date.getTime())) {
						//   var today = new Date();
						//   date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
						// }
						controller.$dateValue = date;
						return getDateFormattedString();
					});

					// viewValue -> element
					controller.$render = function () {
						// console.warn('$render("%s"): viewValue=%o', element.attr('ng-model'), controller.$viewValue);
						element.val(getDateFormattedString());
					};

					function getDateFormattedString() {
						return !controller.$dateValue || isNaN(controller.$dateValue.getTime()) ? '' : formatDate(controller.$dateValue, options.dateFormat);
					}

					// Garbage collection
					scope.$on('$destroy', function () {
						if (datepicker) datepicker.destroy();
						options = null;
						datepicker = null;
					});
				};
			}
		};
	}]);
})();