'use strict';

(function () {
	var app = angular.module('clickbooth-common');

	app.factory('loginHttpInterceptor', ['$q', '$rootScope', '$timeout', function ($q, $rootScope, $timeout) {
		return {
			// // On request success
			request: function request(config) {
				// Contains the data about the request before it is sent.
				// console.log(config.url, config);
				if (config.url.includes('api')) {
					if (isUndefinedOrNull(config.timeout)) {
						var timeoutDeferred = $q.defer();
						config.timeout = timeoutDeferred.promise;
						config.timeout.deferred = timeoutDeferred;
					}
				}
				// Return the config or wrap it in a promise if blank.
				return config || $q.when(config);
			},

			// // On request failure
			// requestError: function (rejection) {
			// 	console.log(rejection); // Contains the data about the error on the request.
			// 	// Return the promise rejection.
			// 	return $q.reject(rejection);
			// },

			// // On response success
			// response: function (response) {
			// 	// console.log(response); // Contains the data from the response.
			// 	// Return the response or promise.
			// 	return response || $q.when(response);
			// },

			// On response failture
			responseError: function responseError(rejection) {
				if (rejection.headers('Content-Type')) {
					if (rejection.headers('Content-Type').indexOf('json') > -1 && rejection.status == 401) {
						if (angular.isString(rejection.data) && rejection.data) {
							try {
								var rejectData = angular.fromJson(rejection.data);
								if (rejectData && angular.isObject(rejectData)) {
									rejection.data = rejectData;
								}
							} catch (err) {
								// console.log(err.message);
							}
						}
					}
				}

				if (rejection.data && rejection.data.redirect_to) {
					window.location.href = rejection.data.redirect_to;
				} else {
					if (rejection.data && rejection.data.error) {
						if (rejection.data.error.message && rejection.data.error.stacktrace) {
							var method = console.exception ? 'exception' : 'error';
							console[method](rejection.data.error.message + "\n" + rejection.data.error.stacktrace);
						}
					}
				}

				if (rejection.data && rejection.data.code == 42) {
					$rootScope.$message = rejection.data.message;
					$timeout(function () {
						$rootScope.$message = null;
					}, 12000);
				}

				// Return the promise rejection.
				return $q.reject(rejection);
			}
		};
	}]);

	app.config(['$httpProvider', function ($httpProvider) {
		$httpProvider.useApplyAsync(true);
		$httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
		$httpProvider.defaults.headers.common["X-Angular-Referrer"] = window && window.location ? window.location : "";
		$httpProvider.interceptors.push('loginHttpInterceptor');
	}]);
})();