let _prefix = 'tcf-';

let directive_attributes = {
    id: _prefix + 'id', // to activate table filtering add this attribute to table
    skip: _prefix + 'skip', // this attribute is added to table th so that column will be excluded from filtering
    splited: _prefix + 'colspan-splited', // mark colspan element with this class after transformation
    splits: _prefix + 'colspan-splits', // mark created elements from colspan splited element with this class
};

let template = `
<button ng-show="ctrl.tabs_group_name !== 'network_drilldown'" 
        ng-click="ctrl.refreshTCFColumns()" 
        class="static-inline-btn dropdown-toggle"
        data-placement="bottom-right"
        data-animation="am-fade-and-slide-bottom" bs-popover
        data-container="body"
        data-template-url="tcf-popover.html" 
        data-auto-close="1" 
        bs-on-show="ctrl.focusSearch">
    <i class="ico-Columns"></i> 
</button>
<script type="text/ng-template" id="tcf-popover.html" ng-show="ctrl.tabs_group_name !== 'network_drilldown'">
    <div class="popover adjust-columns-popover">
        <div class="arrow"></div>
        <div class="popover-container">
            <div class="popover-section">
                <div class="form-group-label">
                    Adjust Columns
                </div>
                <div class="columns-search">
                 <input type="text" ng-model="ctrl.search"
                        placeholder="Search"
                        ng-disabled="ctrl['tcf_filtered_columns'].length == undefined"
                        ng-change="ctrl.refreshTCFColumns(ctrl.search, 500)" id="tcf-search-input">
                    <a class="clear-value" ng-click="ctrl.search = ''; ctrl.refreshTCFColumns();" ng-if="ctrl.search">
                        <i class="ico-Exit"></i>
                    </a>
                </div>
            </div>
            <div class="columns-list">
                <ul>
                    <li ng-repeat="tcf_column in ctrl['tcf_filtered_columns']">                    
                        <div class="ui-checkbox" ng-click="ctrl.setVisibility($index, !tcf_column.is_visible, true);">
                            <input type="checkbox" ng-model="tcf_column.is_visible"                                   
                                   ng-click="tcf_column.is_visible = !tcf_column.is_visible" >
                            <label>{{tcf_column.name}}</label>
                        </div>
                    </li>
                    <li class="no-data" ng-if="ctrl['tcf_filtered_columns'].length == undefined || ctrl['tcf_filtered_columns'].length == 0 ">                      
                        <div>No columns found</div>
                    </li>
                </ul>
            </div>
            
            <div class="popover-footer"> 
                <!--<button> Reset order </button>-->
                <button ng-disabled="ctrl['tcf_filtered_columns'].length == undefined" ng-click="ctrl.disableAll()"> Disable all </button>
                <button ng-disabled="ctrl['tcf_filtered_columns'].length == undefined" ng-click="ctrl.enableAll()"> Enable all </button>
            </div>
        </div>
    </div>
</script>
`;

function getSaveId(tcf_id){
    let user_id = config.user.id;
    return _prefix + user_id + '_' + (tcf_id ? tcf_id : '');
}

function saveUserTCFColumns(columns, tcf_id) {
    columns = (typeof columns == 'undefined') ? [] : columns;
    if ( !Array.isArray(columns) || (Array.isArray(columns) && columns.length !== 0)) {
        localStorage.setItem(getSaveId(tcf_id), JSON.stringify(columns));
    }
}

function getUserTCFColumns(tcf_id) {
    if(localStorage.getItem(getSaveId(tcf_id) === 'undefined')){
        return [];
    }
    return JSON.parse(localStorage.getItem(getSaveId(tcf_id)));
}

function searchColumns(columns, search){
    if(search !== undefined && search.length > 0){
        return columns.filter(function(el){
            // console.log(el.name, this);
            return (el.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
        });


    }else{
        return columns;

    }
}

let button_controller =['$scope', '$timeout', '$rootScope', function($scope, $timeout, $rootScope){

    let ctrl_this = this;

    this._prefix = _prefix;
    this.tcf_id = $scope.tcfId;
    this.tcfOnChange = $scope.tcfOnChange;

    this.getTable =  function(){
        let selected_tables = angular.element('table[' + directive_attributes.id + '=' + ctrl_this.tcf_id + ']');

        if(!selected_tables || !(selected_tables && selected_tables[0])) return;

        return angular.element(selected_tables[0]);
    };

    this.focusSearch = function(popover){
        ctrl_this.search = '';
        popover.$element.find('#tcf-search-input').focus();
    };

    this.setVisibility = function(column_array_index, visibility){
        let count_visible = ctrl_this['tcf_columns'].list.reduce(function(acc, el){
            return el.is_visible ? acc+1 : acc;
        }, 0);

        if(count_visible >= 0 || (!visibility)){
            ctrl_this['tcf_columns'].list[column_array_index].is_visible = visibility;
        }

        saveUserTCFColumns(ctrl_this['tcf_columns'], ctrl_this.tcf_id);
    };


    this.refreshTCFColumns = function(search, delay_ms){
        delay_ms = delay_ms || 0;

        if(ctrl_this.delay_refresh_timeout){
            $timeout.cancel(ctrl_this.delay_refresh_timeout);
        }

        ctrl_this.delay_refresh_timeout = $timeout(function(){

            if(ctrl_this['tcf_columns'] !== undefined){
                ctrl_this.tcf_filtered_columns = searchColumns(ctrl_this['tcf_columns'].list, search)
            }

            ctrl_this.focusInput = false;
        }, delay_ms);

        $rootScope.$broadcast(`${ctrl_this.tcf_id}:tcf_columns_refresh`);

    };

    this.disableAll = function () {
        angular.forEach( ctrl_this.tcf_filtered_columns, function(value, key) {
                value.is_visible = false;
        });
        saveUserTCFColumns(ctrl_this['tcf_columns'], ctrl_this.tcf_id);
        ctrl_this.getTable();
        $rootScope.$broadcast(`${ctrl_this.tcf_id}:tcf_columns_refresh`);
    };

    this.enableAll = function () {
        angular.forEach( ctrl_this.tcf_filtered_columns, function(value, key) {
            value.is_visible = true;
        });
        saveUserTCFColumns(ctrl_this['tcf_columns'], ctrl_this.tcf_id);
        ctrl_this.getTable();
        $rootScope.$broadcast(`${ctrl_this.tcf_id}:tcf_columns_refresh`);
    };

    // ----- events handling

    $scope.$watch(function(){
        return localStorage.getItem(getSaveId(ctrl_this.tcf_id));
    }, function(n){
        if(n !== undefined && n !== null){
            let changed_tcf_columns = JSON.parse(n);
     let basic_statistic_columns = $rootScope.basic_statistic_columns;
     let basic_changed_columns = [];
     if (changed_tcf_columns.list != undefined && basic_statistic_columns != undefined) {
         if (changed_tcf_columns.list.length > basic_statistic_columns.length) {
             for (let i = 0; i < changed_tcf_columns.list.length; i++) {
                 if ((basic_statistic_columns.indexOf(changed_tcf_columns.list[i].name)) != -1) {
                     basic_changed_columns.push(changed_tcf_columns.list[i]);
                 }
             }

             changed_tcf_columns.list = basic_changed_columns;
         }
 }
            ctrl_this['tcf_columns'] = changed_tcf_columns;

            if($scope.tcfOnChange){
                $scope.tcfOnChange({tcf_data: changed_tcf_columns});
            }

            if(ctrl_this.delay_refresh_timeout){
                $timeout.cancel(ctrl_this.delay_refresh_timeout);
            }

            ctrl_this.delay_refresh_timeout = $timeout(function(){

                ctrl_this.tcf_filtered_columns = searchColumns(changed_tcf_columns.list, ctrl_this.search);

                ctrl_this.focusInput = false;
            }, 500);
        }

    });

    $scope.$watch(function(){
        return $scope.tcfOff;
    }, function(val){
        ctrl_this.tcfOff = val;
    });

    $rootScope.$watch(function(){
        return $rootScope.isEnableAllClicked;
    },function(n) {
        ctrl_this.enableAll();
    });

}];

let table_controller = ['$scope', '$rootScope', '$element', '$attrs', '$timeout', '$compile', function($scope, $rootScope, $element, $attrs, $timeout, $compile){

    this._prefix = _prefix;
    this._table = ae($element);
    this.tcf_id = $attrs.tcfId;

    let ctrl_this = this;

    this.loadUserSavedVisibility = function(){
        ctrl_this['tcf_columns'] = getUserTCFColumns(ctrl_this.tcf_id);
        if(ctrl_this['tcf_columns'] && ctrl_this.tcfOff !== true){
            if (ctrl_this['tcf_columns'] && ctrl_this['tcf_columns'].list && ctrl_this['tcf_columns'].tcf_id) {
                for (let i = 0; i < ctrl_this['tcf_columns'].list.length; i++) {
                    ctrl_this.changeTCFColumnVisiblityByIndex(ctrl_this['tcf_columns'].list[i].index, ctrl_this['tcf_columns'].list[i].is_visible)
                }
            }
        }
    };

    this.getFilterableColumns  = function (tcf_table, search, include_hidden){
        let table = tcf_table;
        let columns_list = [];

        let table_data_titles = ae(ae(table).find('tbody tr')[0]).find('td[data-title]');

        for(let i = 0; i < table_data_titles.length; i++){
            let td_data_title = angular.element(table_data_titles[i]);

            if((table_data_titles[i].hasAttribute(directive_attributes.skip) && $scope.$eval(td_data_title.attr(directive_attributes.skip)) !== false) || (!include_hidden && td_data_title.is(':hidden'))) continue;

            let column_name = td_data_title.attr('data-title').trim() || '';
            let column_data = {
                name: column_name,
                index: ae(ae(table).find('tbody tr')[0]).children().index(table_data_titles[i]),
                is_visible: true,//td_data_title.is(':visible')
            };
            if(column_data.name == '' || (search !== undefined
                && typeof search === 'string' && column_data.name
                && column_data.name.toLowerCase().indexOf(search.toLowerCase()) === -1)){
                continue;
            }

            columns_list.push(column_data);
        }
        return {
            tcf_id: angular.element(table).attr(directive_attributes.id),
            list: columns_list
        };
    };

    this.initTCF = function(){

        ctrl_this.tcfReloadVisibility();

        ctrl_this._table.css('visibility', 'visible');
        ctrl_this._table.find('.sortable').show();

        $scope.tcf_init_done = ctrl_this.tcf_init_done = $rootScope.tcf_init_done = true;
    };

    this.prepareTCFTable = function (){
        if(ctrl_this._table === undefined) return;
        let table = ctrl_this._table;

        let table_trs = table.find('tr');
        for(let tr_i = 0; tr_i < table_trs.length; tr_i++){
            let tr_tds = angular.element(table_trs[tr_i]).find('td');

            for(let td_i = 0; td_i < tr_tds.length; td_i++){
                if(angular.element(tr_tds[td_i]).attr('colSpan')){
                    let colspan = angular.element(tr_tds[td_i]).attr('colSpan');

                    let split = angular.element(tr_tds[td_i]).removeAttr('colSpan').addClass(directive_attributes.splited);

                    for(let l = 0; l < colspan-1; l++){
                        let td = angular.copy(split);
                        $compile(td)($scope);
                        td.addClass(directive_attributes.splits);
                        td.insertAfter(tr_tds[td_i]);
                    }
                }
            }
        }
    };

    this.refreshTCFColumns = function(delay_ms){
        if(ctrl_this['tcf_columns'] && ctrl_this.tcfOff == true ) return;

        delay_ms = delay_ms || 0;

        let table = ctrl_this._table;

        if(ctrl_this.delay_refresh_timeout){
            $timeout.cancel(ctrl_this.delay_refresh_timeout);
        }

        ctrl_this.delay_refresh_timeout = $timeout(function(){
            let filterable_columns = getUserTCFColumns(ctrl_this.tcf_id);
            let current_filterable_columns = ctrl_this.getFilterableColumns(table, undefined, true);

            if(filterable_columns === undefined || filterable_columns === null || filterable_columns.list === undefined){
                filterable_columns = Object.assign({}, filterable_columns, current_filterable_columns);
                saveUserTCFColumns(filterable_columns, ctrl_this.tcf_id);
            }

            // let new_columns = current_filterable_columns.list.filter( x => filterable_columns.list.findIndex( c => c.name == x.name ) == -1);
            //
            // if(new_columns.length > 0 ){
            //     filterable_columns.list = filterable_columns.list.concat(new_columns);
            //     saveUserTCFColumns(filterable_columns, ctrl_this.tcf_id);
            // }

            let new_list = [];

            current_filterable_columns.list.forEach(function (new_col) {
                let existing_column = filterable_columns.list.find(x => x.name == new_col.name);

                if(existing_column !== undefined){
                    new_col.is_visible = existing_column.is_visible;
                    new_list.push(new_col);
                }else{
                    new_list.push(new_col);
                }
            });

            filterable_columns.list = new_list;

            saveUserTCFColumns(filterable_columns, ctrl_this.tcf_id);

            ctrl_this['tcf_columns'] =  filterable_columns;

            ctrl_this.focusInput = false;
        }, delay_ms);

    };

    this.changeTCFColumnVisiblityByIndex = function (tcf_column_index, column_visibility, save){
        let table = ctrl_this._table;

        if(table === undefined) return;

        let trs = table.find('tr');

        for(let i = 0; i < trs.length; i++){
            let tr_children = angular.element(trs[i]).children();
            let hide_this = angular.element(tr_children[tcf_column_index]);

            if (column_visibility !== undefined) {
                if (column_visibility === true) {
                    hide_this.removeClass('hidden');
                } else {
                    hide_this.addClass('hidden');
                }
            } else {
                hide_this.toggle()
            }

        }

        window.dispatchEvent(new Event('resize'));

        if(save == true){
            saveUserTCFColumns(ctrl_this['tcf_columns'], ctrl_this.tcf_id);
        }
    };

    this.tcfReloadVisibility = function(){

        ctrl_this.loadUserSavedVisibility();

        ctrl_this._table.find('tbody tr').removeClass('hidden');
    };

    this.tcfOnFirstRepeat = function(){
        //do nothing
    };

    this.tcfOnLastRepeat = function(){
        ctrl_this.tcfReloadVisibility();
    };

    $scope.$watch(function () {
        return ctrl_this ? ctrl_this.tcfOff : undefined;
    }, function tcfOffWatchAction(value) {

        if(ctrl_this.tcf_init_done == true){
            if(value == true && data_get(ctrl_this, 'tcf_columns.list.length', false)){
                for (let i = 0; i < ctrl_this['tcf_columns'].list.length; i++) {
                    ctrl_this.changeTCFColumnVisiblityByIndex(ctrl_this['tcf_columns'].list[i].index, true)
                }
            }else{
                ctrl_this.loadUserSavedVisibility();
            }
        }

    });

    $scope.$on(`${ctrl_this.tcf_id}:tcf_columns_saved`, () => {
        ctrl_this['tcf_columns'] = getUserTCFColumns(ctrl_this.tcf_id);

    });

    $scope.$watch(function(){
        return $scope.$eval($attrs.tcfOff);
    }, function(val){
        ctrl_this.tcfOff = val;

        if(val !== true){
            ctrl_this.prepareTCFTable();
        }
    });

    $scope.$on(`${ctrl_this.tcf_id}:tcf_columns_refresh`, () => {
        ctrl_this.refreshTCFColumns();
    });

    $scope.$watch(function(){
        return localStorage.getItem(getSaveId(ctrl_this.tcf_id));
    }, function(n){
        ctrl_this.loadUserSavedVisibility();

    });

    $scope.$watch(function(){
        return $scope.$eval($attrs.tcfHide);
    }, function(val){
        if(val == true){
            ctrl_this._table.find('tbody tr').addClass('hidden');

            $rootScope.table_loading = true;
        }
    });

}];


export {template, button_controller, table_controller}
