'use strict';

(function () {
	var app = angular.module('clickbooth-common');
	var lastID = new Date().getTime() / 1000;

	function uniqueId() {
		lastID++;
		return lastID;
	}

	app.directive('label', function ($compile) {
		return {
			restrict: 'A',
			link: function link(scope, elem, attrs) {
				var type = elem.attr('type');

				if (type == 'checkbox') {
					var label = $compile('<label>' + attrs.label + '</label>')(scope);
					label.insertAfter(elem);

					var id = attrs.id || function () {
						id = 'checkbox-' + uniqueId();
						elem.attr('id', id);
						return id;
					}();

					label.attr('for', id);
				} else if (type == 'email' || type == 'text' || type == 'password') {

					var label = $compile('<label>' + attrs.label + '</label>')(scope);
					label.insertAfter(elem);

					var id = attrs.id || function () {
						id = type + '-' + uniqueId();
						elem.attr('id', id);
						return id;
					}();

					label.attr('for', id);

					$compile('<span class="highlight"></span>')(scope).insertAfter(elem);
					$compile('<span class="bar"></span>')(scope).insertAfter(elem);
				}
			}
		};
	});

	app.directive('formControl', function () {
		return {
			restrict: 'C',
			link: function link(scope, elem, attrs) {
				// if ( ! attrs.ngModel) {
				function check() {
					var val = elem.val();

					if (val) {
						elem.addClass('has-value');
					} else {
						elem.removeClass('has-value');
					}
				}

				$(document).ready(function () {
					check();
				});

				elem.on('click keypress mouseup focus blur', check);
				//$document.ready('check');

				scope.$evalAsync(function () {
					check();
				});
				// }
				scope.$watch(function () {
					check();
				});
			}
		};
	});
	// <label for="cb1">Check me</label>

	var started = false;
	var interval;

	app.directive('px', function () {
		return {
			restrict: 'A',
			link: function link(scope, elem, attrs) {

				function refreshSession() {
					var sessionId = new Date().getTime();
					elem.attr('src', elem.data('src') + "&s=" + sessionId);
				}

				refreshSession();

				$(document).on('refreshToken', refreshSession);
				//if ( ! started) {
				//	started = false;
				//	clearInterval(interval);
				//	interval = setInterval(function() {
				//}, 4000);
				//}
			}
		};
	});
})();