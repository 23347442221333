'use strict';

var app = angular.module('clickbooth-common').factory('modalCreativePreview', function ($modal, $rootScope, config, MMRCases) {
    var scope = $rootScope.$new(true);
    scope.config = config;
    scope.service = MMRCases;

    scope.getMMRCreativeImage = function (creative) {
        var preview = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        if (creative.file.id == 0) {
            return scope.service.getTempMMRCreativeImage(creative, preview);
        }

        return scope.service.getMMRCreativeImage(creative, preview);
    };

    var modal = $modal({
        scope: scope,
        templateUrl: template('creative-preview-modal/creative-preview'),
        show: false
    });

    return {
        show: function show(creative, index) {
            scope.creative = creative;
            modal.$promise.then(modal.show);
            scope.index = index;
        }
    };
});