'use strict';

angular.module('clickbooth-common').directive('mmMenuToggle', function () {
	return {
		restrict: 'C',
		link: function link(scope, elem, attrs) {

			scope.$on('$stateChangeSuccess', function (event, curr) {
				setTimeout(function () {
					$('body').removeClass('open-menu');
					$('.mm-menu-toggle').removeClass('active');
				}, 300);
			});

			elem.on('click', function () {
				$('body').toggleClass('open-menu');
				$('.mm-menu-toggle').toggleClass('active');
			});
		}
	};
});