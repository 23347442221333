'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var app = angular.module('clickbooth-common');

var functions_collection = function functions_collection(CityRepository) {
    return {
        prepareSelectedMatch: function prepareSelectedMatch(selected_array, item_properties, max_size_of_string) {
            var additional_options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
                empty_placeholder: 'All'
            };


            if (!selected_array || !item_properties) return;

            if (!selected_array.length) {
                return additional_options.empty_placeholder;
            }

            if (!Array.isArray(item_properties)) {
                item_properties = [item_properties];
            }

            if (!max_size_of_string) {
                additional_options.max_item_count = 1;
            }

            var selected_match = [];

            for (var i = 0; i < selected_array.length; i++) {
                if (additional_options.max_item_count !== undefined && additional_options.max_item_count <= i) {
                    return selected_array.length + ' selected';
                }

                var selected_match_item = [];

                for (var k = 0; k < item_properties.length; k++) {
                    var item_property = item_properties[k];

                    if (!selected_array[i].hasOwnProperty(item_property)) continue;

                    if ((selected_match.join('') + selected_array[i][item_property]).length > max_size_of_string) {
                        return selected_array.length + ' selected';
                    }

                    selected_match_item.push(selected_array[i][item_property]);
                }

                selected_match.push(selected_match_item.join(' '));
            }

            return selected_match.join(', ');
        },

        prepareMultiSelectMatch: function prepareMultiSelectMatch(ctrl, selected_array, item_properties, max_size_of_string, show_number_items_if_count_greater_than) {
            if (!selected_array || !item_properties) return;

            if (!Array.isArray(item_properties)) {
                item_properties = [item_properties];
            }

            if (!max_size_of_string) {
                show_number_items_if_count_greater_than = 1;
            }

            var selected_match = '';
            var items_to_show = [];

            var _loop = function _loop(i) {

                if (show_number_items_if_count_greater_than <= i) {
                    return {
                        v: selected_array.length + ' selected'
                    };
                }

                var skipItemCondition = selected_array[i].type == 'main' ? selected_array.find(function (x) {
                    return x.type != 'main' && x.main_id == selected_array[i].id;
                }) ? true : false : selected_array[i].type == 'primary' ? selected_array.find(function (x) {
                    return x.type != 'primary' && x.type != 'main' && x.primary_id == selected_array[i].primary_id;
                }) ? true : false : false;

                if (skipItemCondition) {
                    return 'continue';
                }
                items_to_show.push(selected_array[i]);

                for (var k = 0; k < item_properties.length; k++) {
                    var item_property = item_properties[k];

                    if (!selected_array[i].hasOwnProperty(item_property)) continue;

                    if (selected_match.concat(i == 0 ? '' : ', ', selected_array[i][item_property]).length > max_size_of_string) {
                        return {
                            v: selected_array.length + ' selected'
                        };
                    }

                    if (i == 0) {
                        selected_match += '';
                    }

                    if (item_property == 'main_name' || item_property == 'primary_name') {
                        selected_match += selected_array[i][item_property] + ' > ';
                    } else {
                        selected_match += selected_array[i][item_property];
                    }
                }

                if (selected_array.length > 1 && i != selected_array.length - 1) {
                    selected_match += ', ';
                }
            };

            for (var i = 0; i < selected_array.length; i++) {
                var _ret = _loop(i);

                switch (_ret) {
                    case 'continue':
                        continue;

                    default:
                        if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
                }
            }

            selected_match = selected_match.trim();

            if (selected_match[selected_match.length - 1] == ',') {
                selected_match = selected_match.slice(0, selected_match.length - 1);
            }

            if (!isUndefinedOrNull(ctrl.link) && !isUndefinedOrNull(ctrl.link.traffic_type) && ctrl.link.traffic_type.length > 0) {
                if (ctrl.link.traffic_type.find(function (x) {
                    return x.type == 'main';
                })) {
                    ctrl.isMainSelected = true;
                } else {
                    ctrl.isMainSelected = false;
                }

                ctrl.adperioFlag = !ctrl.isMainSelected;
            }

            return items_to_show;
        },

        removeAutocomplete: function removeAutocomplete() {
            var tl_dropdown = document.getElementById('tl-dropdown');
            var tl_dropdownArr = tl_dropdown.getElementsByTagName("input");
            for (var i = 0; i < tl_dropdownArr.length; i++) {
                tl_dropdownArr[i].autocomplete = 'none';
            }

            var tl_dropdown_excluded = document.getElementById('tl-excluded-dropdown');
            var tl_dropdown_excludedArr = tl_dropdown_excluded.getElementsByTagName("input");
            for (var _i = 0; _i < tl_dropdown_excludedArr.length; _i++) {
                tl_dropdown_excludedArr[_i].autocomplete = 'none';
            }
        },
        generateFilterCitiesFunction: function generateFilterCitiesFunction(ctrl) {
            CityRepository.getCities().then(function (response) {
                ctrl.cities = response;
            });

            return function filterCities(criteria) {
                if (typeof ctrl.cities === 'undefined') {
                    return;
                }

                ctrl.cities_targeting_list = [];

                if (criteria === null || criteria === '') {
                    ctrl.cities_targeting_list = ctrl.cities.slice(0, 50);
                    return;
                }

                var newCities = [];

                for (var i = 0; i < ctrl.cities.length; i++) {
                    var metro = ctrl.cities[i].metro_code !== '' && ctrl.cities[i].metro_code !== null ? '(' + ctrl.cities[i].metro_code + ')' : '';
                    var name = ctrl.cities[i].name + metro + ', ' + ctrl.cities[i].state;

                    if (name.toLowerCase().includes(criteria.toLowerCase())) {
                        newCities.push(ctrl.cities[i]);
                    }
                }

                ctrl.cities_targeting_list = newCities;
            };
        }
    };
};

app.service('CommonFunctions', ['CityRepository', functions_collection]);