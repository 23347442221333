'use strict';

angular.module('clickbooth-common').service('TagAssignModal', ['$rootScope', '$modal', 'TagPropertySettingService', 'TagsService', 'CommonFunctions', function ($rootScope, $modal, TagPropertySettingService, TagsService, CommonFunctions) {
    var scope = $rootScope.$new(true);
    var modal = $modal({
        scope: scope,
        templateUrl: template('modals/tag-assign-modal'),
        backdrop: 'static',
        show: false
    });
    return {
        show: function show(properties, options, proceed) {
            scope.data = {};
            scope.data.properties = properties;
            scope.proceed = proceed;
            scope.modalOptions = options;
            scope.common = CommonFunctions;

            TagsService.query({ paginate_by: 500 }).then(function (response) {
                scope.tags = response.data;
            });

            scope.assignTags = function (hide) {

                TagPropertySettingService.save({
                    property_ids: scope.data.properties.map(function (x) {
                        return x.id;
                    }),
                    type: scope.modalOptions.type,
                    tag_ids: scope.data.selected_tags.map(function (x) {
                        return x.id;
                    })
                }).fetching(this, 'fetching').then(function (response) {
                    hide();
                    delete scope.data.selected_tags;
                }, function (error_response) {
                    scope.errors = error_response.errors;
                }).then(function () {
                    scope.proceed();
                });
            };

            modal.$promise.then(modal.show);
        }
    };
}]);