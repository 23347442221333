'use strict';

var app = angular.module('clickbooth-common');

app.directive('multipleLineField', function () {
    return {
        scope: {
            controller: '=parentctrl',
            hideEdit: '=',
            hidePreview: '='
        },
        templateUrl: template('multiple-line-field/template'),
        link: function link(scope, element, attr) {

            var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
            // target
            var list = document.querySelector('#' + attr.id + ' section ol.multiple-line');
            // options
            var config = {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true
            };
            scope.list_values = [];
            scope.has_changed = false;
            scope.shown_field = attr.id;

            if (isUndefinedOrNull(list)) {
                return;
            }

            list.addEventListener('keydown', function (e) {
                if (e.key === 'Backspace' && scope.list_values.length < 1 && scope.has_changed) {
                    e.preventDefault();
                }
            });

            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type === "characterData" || mutation.type === "childList") {
                        scope.has_changed = true;
                        scope.list_values = [];
                        scope.list_values = [].slice.call(list.children).map(function (node) {
                            return node.innerHTML;
                        }).filter(function (str) {
                            if (str === "<br>") {
                                return false;
                            } else {
                                return true;
                            }
                        });

                        scope.$evalAsync(function () {
                            scope.controller[attr.id] = scope.list_values;
                            scope.controller[attr.id + "_str"] = scope.list_values.join(",").trim();
                        });
                    }
                });
            });
            observer.observe(list, config);
        }
    };
});

app.directive('multipleLineFile', function () {
    return {
        scope: {
            controller: '=parentctrl',
            hideEdit: '=',
            hidePreview: '='
        },
        templateUrl: template('multiple-line-field/uploaded-file')
    };
});