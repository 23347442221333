'use strict';

angular.module('clickbooth-common').service('TagRemoveModal', ['$rootScope', '$modal', 'TagPropertySettingService', 'TagsService', 'CommonFunctions', function ($rootScope, $modal, TagPropertySettingService, TagsService, CommonFunctions) {
    var scope = $rootScope.$new(true);
    var modal = $modal({
        scope: scope,
        templateUrl: template('modals/tag-remove-modal'),
        backdrop: 'static',
        show: false
    });
    return {
        show: function show(properties, options, proceed) {
            scope.data = {};
            scope.data.properties = properties;
            scope.proceed = proceed;
            scope.modalOptions = options;
            scope.common = CommonFunctions;

            scope.tags_on_properties = [];

            properties.forEach(function (prop) {
                prop.tags.forEach(function (tag) {
                    if (scope.tags_on_properties.find(function (x) {
                        return x.id === tag.id;
                    }) === undefined) {
                        scope.tags_on_properties.push(tag);
                    }
                });
            });

            scope.removeTags = function (hide) {
                var remove_property_tags = [];

                scope.data.selected_tags.forEach(function (tag) {
                    scope.tags_on_properties = scope.tags_on_properties.filter(function (x) {
                        return x.id !== tag.id;
                    });
                    scope.data.properties.forEach(function (prop) {
                        var properties_with_this_tag = prop.tags.filter(function (x) {
                            return x.id === tag.id;
                        });
                        remove_property_tags = remove_property_tags.concat(properties_with_this_tag);
                    });
                });

                TagPropertySettingService.massDelete({ tps: remove_property_tags }).then(function (resp) {
                    hide();
                    delete scope.data.selected_tags;
                }, function (resp) {
                    scope.errors = resp.errors;
                }).then(function () {
                    scope.proceed();
                });
            };

            modal.$promise.then(modal.show);
        }
    };
}]);