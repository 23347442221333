function isFeatureEnabled(feature_name, network_id)
{
	if(typeof feature_name == "undefined" || feature_name == "")
	{
		return false;
	}

	if(!network_id){
		network_id = (config.user.network_id == null ? 1 : config.user.network_id);
	}

	var value = network_id + '_';

	return config && config.features && config.features.indexOf(value + feature_name) >= 0;
}

window.isFeatureEnabled = isFeatureEnabled;

function isFeatureEnabledForUser(feature_name, user, network_id) {
	let user_feature;

	if(user && user.user_feature_settings){
		for (let i = 0; i < user.user_feature_settings.length; i++) {
			if (user.user_feature_settings[i].user_feature.alias == feature_name) {
				if(!isUndefinedOrNull(network_id) && network_id == user.user_feature_settings[i].user_feature.network_id) {
                    user_feature = user.user_feature_settings[i];
                } else if(!network_id) {
                    user_feature = user.user_feature_settings[i];
                }
			}
		}
	}

	if(user_feature){
		return user_feature.enabled == true;
	}else{
		return isFeatureEnabled(feature_name, network_id ? network_id : (user ? user.network_id : undefined));
	}
}
window.isFeatureEnabledForUser = isFeatureEnabledForUser;

function prepareDateFormat (date) {

    function pad0 (n) {
        n = n + '';
        return (n.length < 2) ? '0' + n : n;
    }

    return date.getUTCFullYear() + '-' + pad0(date.getMonth() + 1) + '-' + pad0(date.getDate());
}
window.prepareDateFormat = prepareDateFormat;

function prepareUTCDateFormat (date) {

	function pad0 (n) {
		n = n + '';
		return (n.length < 2) ? '0' + n : n;
	}

	return date.getUTCFullYear() + '-' + pad0(date.getUTCMonth() + 1) + '-' + pad0(date.getUTCDate());
}
window.prepareUTCDateFormat = prepareUTCDateFormat;

window.keyBy = function(array, property_name){
	return Object.values(array).reduce((hashmap,object) => {
		hashmap[object[property_name]] = object
		return  hashmap
	},{})
}

window.copyToClipboard = function copyToClipboard(text) {
	const el = document.createElement('textarea');  // Create a <textarea> element
	el.value = text;                                 // Set its value to the string that you want copied
	el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
	el.style.position = 'absolute';
	el.style.left = '-9999px';                      // Move outside the screen to make it invisible
	document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
	const selected =
		document.getSelection().rangeCount > 0        // Check if there is any content selected previously
			? document.getSelection().getRangeAt(0)     // Store selection if found
			: false;                                    // Mark as false to know no selection existed before
	el.select();                                    // Select the <textarea> content
	document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
	document.body.removeChild(el);                  // Remove the <textarea> element
	if (selected) {                                 // If a selection existed before copying
		document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
		document.getSelection().addRange(selected);   // Restore the original selection
	}
};

window.copyToClipboardFormated = function copyToClipboard(text_plain, text_html) {

	const el = document.createElement("div");
	el.innerHTML = text_html;
	el.contentEditable = true;
	el.readOnly = false;
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);
	let selected = selectElementContents(el);

	const copyListener = event => {
		let clipboardData = event.clipboardData || window.clipboardData || event.originalEvent.clipboardData;

		clipboardData.setData( 'text/html', text_html );
		clipboardData.setData( 'text/plain', text_plain );

        event.preventDefault();
    };

    document.addEventListener( 'copy', copyListener );
	document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
	document.removeEventListener( 'copy', copyListener );

	document.body.removeChild(el);

	if (selected) {                                 // If a selection existed before copying
		document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
		document.getSelection().addRange(selected);   // Restore the original selection
	}
};

function selectElementContents(el) {
	var range = document.createRange();
	range.selectNodeContents(el);
	var sel = window.getSelection();

	if(sel.rangeCount === 0) return false;

	sel.removeAllRanges();
	sel.addRange(range);

	return sel.getRangeAt(0);
};

function calcTime() {

    let florida_offset = -4;
    // create Date object for current location
    let d = new Date();

    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    let nd;
    nd = new Date(utc + (3600000 * florida_offset));

    function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
        let jan = new Date(t.getFullYear(),0,1);
        let jul = new Date(t.getFullYear(),6,1);
        return Math.min(jan.getTimezoneOffset(),jul.getTimezoneOffset()) == t.getTimezoneOffset();
    }

    // getTimezoneOffset returns a different value during DST and standard time
    // for example New York returns -5 normally and -4 during DST
    // Sarasota, Florida is GMT/UTC - 5h during Standard Time
    // Sarasota, Florida is GMT/UTC - 4h during Daylight Saving Time
    // not all countries uses Daylight Saving Time
    let dst = isDST(nd);
    if(!dst) {
        florida_offset += -1;
        nd = new Date(utc + (3600000 * (florida_offset)));
    }

    return nd;

}
window.calcTime = calcTime;

function getDateRanges ( selectedDateRange) {

	let today = calcTime();
	var firstday, lastDay;
	var from_date = null;
	var until_date = null;
	var deltaFromDate = null;
	var deltaToDate = null;

	function daysInMonth (month, year) {
		return new Date(year, month, 0).getDate();
	}

	switch (selectedDateRange) {
		case 'today':
			from_date = new Date(today);
			until_date = new Date(today);
            deltaFromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
            deltaToDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
			break;
		case 'yesterday':
			var yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);
			from_date = yesterday;
			until_date = yesterday;
			break;
		case 'week_to_date':
			var first = today.getDate() - today.getDay() + 1;
			if (today.getDay() == 0) {
				first = today.getDate() - 6;
			}
			firstday = new Date(today);
			firstday.setDate(first);
			from_date = firstday;
			until_date = today;
			var deltaFrom = new Date();
			deltaFrom.setFullYear(firstday.getFullYear(),firstday.getMonth(), firstday.getDate() - 7);
			var deltaTo = new Date(until_date);
			deltaTo.setDate(deltaTo.getDate() - 7);
			deltaFromDate = deltaFrom;
			deltaToDate = deltaTo;
			break;
		case 'last_week':
			var d = new Date(today);
			d.setDate(d.getDate() - 7 - d.getDay() + 1);
			from_date = d;
			var lastweek_lastdat = new Date(d);
			lastweek_lastdat.setDate(d.getDate() + 6);
			until_date = lastweek_lastdat;
			break;
		case 'last_month':
			firstDay = new Date(today);
			firstDay.setDate(1);
			firstDay.setMonth(firstDay.getMonth() - 1);
			lastDay = new Date(today);
			lastDay.setDate(1);
			lastDay.setDate(lastDay.getDate() - 1);
			from_date = firstDay;
			until_date = lastDay;
			break;
		case 'month_to_date':
			firstDay = new Date(today);
			firstDay.setDate(1);
			from_date = firstDay;
			until_date = today;

			var dateDeltaFrom = new Date(today);
			var dateDeltaTo = new Date(today);
			var deltaToSetted = false;

			// if month is january and need to compare with december of last year
			if (firstDay.getMonth() - 1 < 0) {
				dateDeltaFrom.setFullYear(firstDay.getFullYear() - 1, 11, 1);
				dateDeltaTo.setFullYear(firstDay.getFullYear() - 1, 11, dateDeltaTo.getDate());
				deltaToDate = dateDeltaTo;
				deltaToSetted = true;
			} else {
				dateDeltaFrom.setMonth(firstDay.getMonth() - 1, 1);
			}
			deltaFromDate = dateDeltaFrom;

			//if month is march and need to compare with february
			if ((dateDeltaTo.getMonth() - 1 == 1) && ((dateDeltaTo.getDate() == 31) || (dateDeltaTo.getDate() == 30)
				|| (dateDeltaTo.getDate() == 29)) && (dateDeltaTo.getFullYear() % 4 != 0)) {

				dateDeltaTo.setMonth(1, 28);
			} else if ((dateDeltaTo.getFullYear() % 4 == 0) && (dateDeltaTo.getMonth() - 1 == 1) && ((dateDeltaTo.getDate() == 31)
				|| (dateDeltaTo.getDate() == 30) || (dateDeltaTo.getDate() == 29))) {

				dateDeltaTo.setMonth(1, 29);
			}
			// if this month has 31 day and last month 30
			else if ((dateDeltaTo.getDate() == 31) && (dateDeltaTo.getMonth() != 0) && !deltaToSetted) {

				if (daysInMonth(dateDeltaTo.getMonth(), dateDeltaTo.getFullYear()) == 30) {
					dateDeltaTo.setMonth(dateDeltaTo.getMonth() - 1, 30);
				} else {
					dateDeltaTo.setMonth(dateDeltaTo.getMonth() - 1, 31);
				}
			}
			// all other cases
			else if ((dateDeltaTo.getMonth() != 0) && (dateDeltaTo.getDate() != 31) && !deltaToSetted) {
				dateDeltaTo.setMonth(dateDeltaTo.getMonth() - 1, dateDeltaTo.getDate());
			}
			deltaToDate = dateDeltaTo;
			break;
		case 'year_to_date':
			var firstDay = new Date(today);
			firstDay.setMonth(0);
			firstDay.setDate(1);
			from_date = firstDay;
			until_date = today;
			var deltadatefrom = new Date(today);
			var deltadateto = new Date(today);
			deltadatefrom.setFullYear(deltadatefrom.getFullYear() - 1, 0, 1);
			deltadateto.setFullYear(deltadateto.getFullYear() - 1, deltadateto.getMonth(), deltadateto.getDate());
			deltaFromDate = deltadatefrom;
			deltaToDate = deltadateto;
			break;
		case 'all':
			var lastDay = new Date(today);
			until_date = new Date(today);
			lastDay.setFullYear(1970, 0, 1);
			from_date = lastDay;
			break;
		default:
			from_date = today || '';
			until_date = '';
	}

	var resultArray = [from_date, until_date, deltaFromDate, deltaToDate];
	return resultArray;
}
window.getDateRanges = getDateRanges;

function changeSubscribingModal(token) {
	let t = token.split("=");;
	document.getElementById('subscribeModalBody').innerHTML = "<h2 class='text-center'>Success!</h2><p class='text-center'>We’ve sent the request to your account manager, they will reach out to you soon!</p>";
	document.getElementById('subscribeModalHeader').innerHTML = "<div class='check-circle'><i class='ico-Check'></i></div>";
	document.getElementById('buttonClose').innerHTML = "Close";
	document.getElementById('buttonClose').classList.add("button-primary-filled");
	document.getElementById('buttonClose').style.width = "100%";
	document.getElementById('buttonSend').style.display = "none";
	$.ajax({
		 type:'post',
		url:'/marketers/send-interest-notification',
		data: {"_token" : t[1]},
		success:function(e) {

		}
	});
}
window.changeSubscribingModal = changeSubscribingModal;

function getWatchers(root) {
	root = angular.element(root || document.documentElement);
	var watcherCount = 0;
	var watch_per_element =[];
	function getElemWatchers(element) {
		var isolateWatchers = getWatchersFromScope(element.data().$isolateScope);
		var scopeWatchers = getWatchersFromScope(element.data().$scope);
		var watchers = scopeWatchers.concat(isolateWatchers);
		angular.forEach(element.children(), function (childElement) {
			watchers = watchers.concat(getElemWatchers(angular.element(childElement)));
		});
		//console.log(element);

		watch_per_element[watch_per_element.length] = {count:watchers.length, element: element};
		return watchers;
	}

	function getWatchersFromScope(scope) {
		if (scope) {
			return scope.$$watchers || [];
		} else {
			return [];
		}
	}

	watch_per_element.forEach(function(el){

	})
	console.log(watch_per_element)
	for(let i = 0; i < watch_per_element.length; i++){
		console.log('Watchers per element: ', watch_per_element[i].count);
	}
	return getElemWatchers(root);
}

window.getWatchers = getWatchers;

// move object property . Example: mvop({a: 1, b: 2, c:3}, {a: 'a2', c: 'a'}) = {a: 3, a2: 1, b: 2}
// if destination property is empty or undefined just remove source property: mvop({a: 1, b: 2, c:3}, {a: '', c: undefined, b: 'changed_property'}) = {changed_property: 2}
// if allow_override = false then disable move property on already existing property
window.mvop = function(obj, object_properties_mapping, allow_override){
	let temp_obj = angular.copy(obj);

	if(temp_obj === undefined) return temp_obj;

	let src_list = Object.keys(object_properties_mapping);

	for(let i = 0; i < src_list.length; i++){
		if(allow_override !== false || (!allow_overrider && !temp_obj.hasOwnProperty(src_list[i]))){
			if(object_properties_mapping[src_list[i]] !== undefined
				&& (object_properties_mapping[src_list[i]].length && object_properties_mapping[src_list[i]].length > 0)
				&& temp_obj.hasOwnProperty(src_list[i])){
				temp_obj[object_properties_mapping[src_list[i]]] = temp_obj[src_list[i]];
			}
		}
	}

	for(let i = 0; i < src_list.length; i++){
		delete temp_obj[src_list[i]];
	}

	return temp_obj;
};

// replace object values
// Example: rol({x: null, a: undefined, y: 'f'},[{src: undefined, dest: 1},{src: 'f', dest: 'changed'},{src: null, dest: 0}])
// Result: {x: 0, a: 1, y: "changed"}
window.rov = function(obj, values_mapping_array){
	if(obj === undefined || obj === null) return obj;

	let temp_obj = angular.copy(obj);
	if(!Array.isArray(values_mapping_array)){
		values_mapping_array = [values_mapping_array];
	}

	Object.keys(temp_obj).forEach( function(prop_name){
		let value_mapping = values_mapping_array.find(x => temp_obj[prop_name] === x.src);
		if(value_mapping !== undefined){
			temp_obj[prop_name] = value_mapping.dest;
		}
	});

	return temp_obj;
};

// simulation of laravel data_get
window.data_get = function getVal(root, access_path, default_value){
	try{
		let found = access_path.split('.').reduce ( (res, prop) => res[prop], root );
		return found === undefined ? default_value : found;
	}catch(e){
		return default_value;
	}
};

function isEmptyObject(obj) {
	for(var key in obj) {
		if(obj.hasOwnProperty(key))
			return false;
	}
	return true;
}

window.isEmptyObject = isEmptyObject;

function toTimezone(dt, time_zone = 'UTC',locale = 'en-US'){
	return new Date(dt.toLocaleString(locale, {timeZone: time_zone}));
}
window.toTimezone = toTimezone;

function asArray(input, clone_array = true){
	if(input === undefined || input === null) {
		input = [];
	}

	return (Array.isArray(input) ? (clone_array === true ? input.slice() : input) : [input]);
}
window.asArray = asArray;

window.prepareAndSetTime = function(date_string, set_time, adjust_timezone = 'UTC'){
	let d = new Date(date_string);

	d.setHours(set_time[0] ? set_time[0] : 0);
	d.setMinutes(set_time[1] ? set_time[1] : 0);
	d.setSeconds(set_time[2] ? set_time[2] : 0);

	return adjust_timezone ? toTimezone(d, adjust_timezone) : d;
};

window.isEqualObjects = function (o1, o2, exclude_fields = []) {
	if(o1 === undefined || o2 === undefined) return false;

	let object_keys = Object.keys(o1).filter(function (elem) {
		return exclude_fields.indexOf(elem) == -1;
	});

	let object_keys2 = Object.keys(o2).filter(function (elem) {
		return exclude_fields.indexOf(elem) == -1;
	});
	;

	if (object_keys.length != object_keys2.length) return false;

	for (let i = 0; i < object_keys.length; i++) {
		let key = object_keys[i];
		if (!o2.hasOwnProperty(key) || (o2.hasOwnProperty(key) && (((o1[key] instanceof Object && o2[key] instanceof Object) && !isEqualObjects(o1[key], o2[key])) || ((!(o1[key] instanceof Object) || !(o2[key] instanceof Object)) && o2[key] !== o1[key])))) {
			return false
		}
	}

	return true;
};

window.findObjectInArray = function (obj, arry, ignore_properties){
	if(!arry) return undefined;
	ignore_properties = ignore_properties || [];

	return arry.find(function (current_array_obj) {
		return isEqualObjects(current_array_obj, obj, ignore_properties)
	})
}

window.getCustomCdnUrl = function(domain, cdn_url) {
	let url = domain + "/" + cdn_url.split("/").pop();
	let parser = document.createElement('a');

	parser.href = url;
	parser.hostname = 'cdn.' + parser.hostname;

	return parser.href;
};

function getUserDisplayName(user_firstname, user_lastname, user_company, user_type, user_network_id){
	if(user_company && user_company.length > 0 && user_type && ['advertiser','affiliate'].indexOf(user_type) >= 0 && isFeatureEnabled('company-name-instead-name-lastname', user_network_id)){
		return user_company;
	}

	return (user_firstname && user_firstname.length > 0 ? user_firstname: '') + (user_lastname && user_lastname.length > 0 ? ' ' + user_lastname: '');
}
window.getUserDisplayName = getUserDisplayName;

/**
 * actually this one returns UTC date
 * @returns {string} ISO date
 */
function getCurrentTimezoneDate()
{
	var date_now = new Date();
	var today_ts = date_now;
	var today_utc = date_now.getTime() - (date_now.getTimezoneOffset() * 60000);
	var today_current_timezone = new Date(today_utc);
	var date = today_current_timezone.toISOString().substring(0, 10);
	return date;
}
window.getCurrentTimezoneDate = getCurrentTimezoneDate;

function prepareDate (date, dateInstance) {
	if (date == '') {
		return '';
	}

	if (!(date instanceof Date)) {
		throw new Error('Parameter date is not an instance of Date');
	}

	// Use a new Date instance
	date = new Date(date);

	// Reset to zero hour
	date.setMinutes(0);
	date.setHours(0);
	date.setSeconds(0);

	//Return the timezone difference between UTC and Local Time
    // If your time zone is GMT+2, -120 will be returned
	var utcOffset = date.getTimezoneOffset();
	var etcOffset = -240;

	var minutes = 0;

	// Reverse for plus zones
	if (utcOffset > 0) {
		minutes = date.getMinutes() + utcOffset;
	}
	else {
		minutes = date.getMinutes() - utcOffset - etcOffset;
	}

	// Reset to UTC
	// date.setMinutes( date.getMinutes() + (utcOffset * -1) );
	date.setMinutes(minutes);

	// Wherever just add the diff from UTC to ETC - Server time
	// if (utcOffset != etcOffset) {
	// date.setMinutes( etcOffset );
	// }

	// Reset to zero hour
	date.setMinutes(0);

	if (dateInstance) {
		return date;
	}

	function pad0 (n) {
		n = n + '';
		return (n.length < 2) ? '0' + n : n;
	}

	return date.getUTCFullYear() + '-' + pad0(date.getMonth() + 1) + '-' + pad0(date.getDate());
}

function getDateHMS(date) {

    let utcOffset = date.getTimezoneOffset();
    let utcDateTime = new Date(date.getTime() + utcOffset * 60 * 1000);

    function pad0(n) {
        n = n + '';
        return (n.length < 2) ? '0' + n : n;
    }
	let day = date.getHours() == '00' ? pad0(date.getDate() - 1) : pad0(date.getDate());
    return date.getUTCFullYear() + '-' + pad0(date.getMonth() + 1) + '-' + day + ' ' + pad0(utcDateTime.getHours()) + ':' + pad0(date.getMinutes()) + ':' + pad0(date.getSeconds());
}
window.getDateHMS=getDateHMS;

/**
 * Rename this to prepareDate when it is confirmed that
 * this function is a better implementation
 * @deprecated
 */
function prepareDate_corrected (date, dateInstance) {
	//console.warn("check if prepareDate_corrected is better then prepareDate then rename");

	if (date == '') {
		return '';
	}

	if (!(date instanceof Date)) {
		throw new Error("Parameter date is not an instance of Date");
	}

	date = new Date(date);

	var hours_multiplier = 2;
	var minutes_multiplier = 2;

	if (date.getTimezoneOffset() <= -720) {
		hours_multiplier = 4;
	}

	var add_hours = date.getTimezoneOffset() < 0 ? (date.getTimezoneOffset() / 60) * hours_multiplier : (date.getTimezoneOffset() / 60) * (hours_multiplier * -1);
	var add_minutes = date.getTimezoneOffset() < 0 ? date.getTimezoneOffset() * (minutes_multiplier * -1) : date.getTimezoneOffset() * minutes_multiplier;

	var hours_adjustment = 0;
	var minutes_adjustment = 0;

	if (date.getTimezoneOffset() < 0) {
		hours_adjustment = Math.floor((date.getTimezoneOffset() * -1) / 60) + (-4 + Math.floor((date.getTimezoneOffset() * -1) / 60));
	}

	if (hours_adjustment) {
		add_hours += hours_adjustment
	}

	if (minutes_adjustment) {
		add_minutes += minutes_adjustment;
	}

	date.setHours(0);
	date.setMinutes(0);
	date.setMinutes(add_minutes);
	date.setHours(add_hours);
	date.setSeconds(0);

	if (dateInstance) {
		return date;
	}

	function pad0 (n) {
		n = n + '';
		return (n.length < 2) ? '0' + n : n;
	}

	return date.getUTCFullYear() + '-' + pad0(date.getMonth() + 1) + '-' + pad0(date.getDate());
}

window.prepareDate = prepareDate;
window.prepareDate_corrected = prepareDate_corrected;

function getChartDateFromTimestamp(timestamp, reset_utc, regular)
{
	var date = new Date(timestamp * 1000);

	if(regular != 'undefined' && regular) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
	}

	if(typeof reset_utc != "undefined" && reset_utc)
	{
		date.setUTCHours(0);
		date.setUTCMinutes(0);
		date.setUTCSeconds(0);
		date.setUTCMilliseconds(0);
	}


	return date;
}
window.getChartDateFromTimestamp = getChartDateFromTimestamp;

window.generateResourceResponsePaging = function generateResourceResponsePaging(total, page, paginate_by){
	let resourceResponse = {
		total: total,
	};

	resourceResponse.current_page = page;
	resourceResponse.last_page = Math.ceil(total / paginate_by);

	resourceResponse.from = total === 0 ? 1 : (page - 1) * paginate_by  + 1;
	resourceResponse.to = total === 0 ? 0 : page * paginate_by;
	if (resourceResponse.to > resourceResponse.total) {
		resourceResponse.to = resourceResponse.total;
	}

	return resourceResponse;
};

function makeid()
{
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < 8; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}
window.makeid = makeid;

function finite (value, defaultReturn) {
	if (!defaultReturn) {
		defaultReturn = false;
	}

	return isFinite(value) ? value : defaultReturn;
}
window.finite = finite;

function isUndefinedOrNull (value) {
	return typeof value == 'undefined' || value === null;
}
window.isUndefinedOrNull = isUndefinedOrNull;

function recalcTableFloatingButton(tab_id) {
    window.dispatchEvent(new Event('resize'));
	setTimeout(function () {
        $("[data-drilldown-tab-id='" + tab_id +"']" + ".table-responsive table.ui-table").find('tbody > tr').each(function (i) {
			$("[data-drilldown-tab-id='" + tab_id +"']" + '.fixed-column tbody > tr:eq('+i+')').height($(this).outerHeight());
		});
		$("[data-drilldown-tab-id='" + tab_id +"']" + ".table-responsive table.ui-table").find('thead > tr').each(function (i) {
			$("[data-drilldown-tab-id='" + tab_id +"']" + '.fixed-column thead > tr:eq('+i+')').height($(this).outerHeight());
		});
	}, 100);
}

window.recalcTableFloatingButton = recalcTableFloatingButton;



function isURL(str) {
    var urlRegex = '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~{}+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$';
    var url = new RegExp(urlRegex, 'i');
    return str.length < 2083 && url.test(str);
}
window.isURL = isURL;

window.ucfirst = function ucfirst(str){
	if(!(str && str.length)) return str;

	return str[0].toUpperCase() + str.toLowerCase().slice(1);
};

window.stripHtml = function stripHtml(str){
	if(!(str && str.lenth)) return str;

	return (str).replace(/(<([^>]+)>)/ig,"")
}

function buildUrl(url, query, remove_params) {

	var parser = document.createElement('a');

	parser.href = url;

	var queryData = parser.search.split('?');

	if (queryData && queryData[0] == "") {
		queryData.shift();
	}

	if(queryData.length && remove_params && remove_params.length) {
		var queryDataParams = [];
		queryData[0].split('&').forEach(function(item) {
			var queryArg = item.split('=');

			if(remove_params.indexOf(queryArg[0]) == -1) {
				queryDataParams.push(item);
			}
		});

		queryData = queryDataParams;

	}

	if(query) {
		for(var key in query) {
			queryData.push(key + "=" + query[key]);
		}
	}

	parser.search = queryData.join("&");

	return parser.href;
}

window.buildUrl = buildUrl;

window.timeZone = 'America/New_York';

function localTS(ts, timezone) {
    if(typeof timezone !== 'undefined'){
        timezone = window.timeZone;
    }
    var a =  moment.tz(ts * 1000, timezone).format('YYYY-MM-DD HH:mm:ss')
    var b = new Date(a);

    return b.getTime();
}
window.localTS = localTS;

function toFloat(value, precision) {
	return parseFloat(parseFloat(value).toFixed(precision ? precision : 2));
}

window.toFloat = toFloat;

window.ae = angular.element;

function getParsedValue(value, low) {
	value = value + "";
	if (low == 0) {
		value = value.replace(/[^0-9.]/g, "");
	} else {
		var negative_match = value.match(/\(\$/);
		value = value.replace(/[^0-9.-]/g, "");
		if (negative_match) {
			value = value.replace(/[^0-9.]/g, "");
			value = -value;
		}
	}
	if (value == "") {
		value = 0;
	}

	return value;
}

window.getParsedValue = getParsedValue;

function getBool(val){
	if(val === undefined) return false;
	let num = +val;
	return !isNaN(num) ? !!num : !!String(val).toLowerCase().replace(!!0,'');
}
window.getBool = getBool;

window.checkPermissions = function(for_user, require_maximum_sensitivity_permission, require_admin_permission){
	if(for_user && for_user.was_admin) return true;
	
	if (for_user.advertiser_tier != 'prime') {
		return true;
	}

	if(require_admin_permission === true && for_user.user_type !== 'admin'){
		return false;
	}

	if(require_maximum_sensitivity_permission !== undefined && for_user.sensitivity && !isNaN(require_maximum_sensitivity_permission) && for_user.sensitivity > require_maximum_sensitivity_permission){
		return false;
	}

	return true;
};

window.checkIsOtherDrilldown = function (drilldown_name) {
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop();

    return lastSegment === drilldown_name;
};

(function () {
	if (!Date.now) {
		Date.now = function () {
			return new Date().getTime();
		}
	}

	var app = angular.module('clickbooth-common', [
		'ngSanitize',
		'ngMessages',
		'ui.router',
		'ui.select',
		'ngAnimate',
		'collection.resource',
		'mgcrea.ngStrap.helpers.debounce',
		'mgcrea.ngStrap.modal',
		'mgcrea.ngStrap.popover',
		'mgcrea.ngStrap.datepicker',
		'mgcrea.ngStrap.collapse',
		'highcharts-ng',
		'mgcrea.ngStrap.tooltip',
		'mgcrea.ngStrap.helpers.parseOptions',
		'mgcrea.ngStrap.select',
		// 'mgcrea.ngStrap.helpers.dateParser',
		// 'mgcrea.ngStrap.helpers.dateFormatter',
		// 'mgcrea.ngStrap.datepicker',
		'mgcrea.ngStrap.dropdown',
		'field.validations',
		'rzModule',
		'ngTable',
		'ngTableResizableColumns',
        'angularMoment',
        'filearts.dragDrop',
		angularDragula(angular)
	]);

	app.factory('CampaignCreatives', ['config', 'Resource', function(config, Resource) {
		var model = new Resource({
			url: config.api + '/campaign-creatives'
		});

		model.creativeUrl = function(id) {
			return config.api + '/offer/' + id + '/creative';
		};

		model.downloadAllCreatives = function(campaign_id){
			return config.api + '/offer/' + campaign_id + '/all-creatives';
		}

		model.downloadActiveCreatives = function(campaign_id){
			return config.api + '/offer/' + campaign_id + '/active-creatives';
		}

		model.downloadAllCreativesMailer = function(campaign_id){
			return config.api + '/offer/' + campaign_id + '/all-creatives-mailer';
		}

		model.downloadActiveCreativesMailer = function(campaign_id){
			return config.api + '/offer/' + campaign_id + '/active-creatives-mailer';
		}

		return model;
	}]);

	//enables or disables modals on homepage, affiliate registration and advetiser registration
	app.value('show_wait_list', false);

	app.value('paginationOptions', [3, 50, 100, 250, 500]);

	//disables dropdown limit if set to boolean false
	app.value('uiSelectLimit', false);

	app.value('dateRangeOptions', [
		{value: "all", name: "All"},
		{value: "today", name: "Today"},
		{value: "yesterday", name: "Yesterday"},
		{value: "week_to_date", name: "Week to Date"},
		{value: "last_week", name: "Last Week"},
		{value: "last_month", name: "Last Month"},
		{value: "month_to_date", name: "Month to Date"},
		{value: "year_to_date", name: "Year to Date"}
	]);

	app.value('hourlyOptions', [
		{value: "all", name: "All"},
		{value: "current", name: "Current"},
		{value: "last_hour", name: "Last Hour + Current"},
		{value: "last_3_hours", name: "Last 3 Hours + Current"},
		{value: "last_6_hours", name: "Last 6 Hours + Current"},
		{value: "custom", name: "Custom"}
	]);

	app.value('dayHours', [
		{value: 0, name: "12 AM"},
		{value: 1, name: "1 AM"},
		{value: 2, name: "2 AM"},
		{value: 3, name: "3 AM"},
		{value: 4, name: "4 AM"},
		{value: 5, name: "5 AM"},
		{value: 6, name: "6 AM"},
		{value: 7, name: "7 AM"},
		{value: 8, name: "8 AM"},
		{value: 9, name: "9 AM"},
		{value: 10, name: "10 AM"},
		{value: 11, name: "11 AM"},
		{value: 12, name: "12 PM"},
		{value: 13, name: "1 PM"},
		{value: 14, name: "2 PM"},
		{value: 15, name: "3 PM"},
		{value: 16, name: "4 PM"},
		{value: 17, name: "5 PM"},
		{value: 18, name: "6 PM"},
		{value: 19, name: "7 PM"},
		{value: 20, name: "8 PM"},
		{value: 21, name: "9 PM"},
		{value: 22, name: "10 PM"},
		{value: 23, name: "11 PM"},
	]);

	app.value('contactTypes', [
		{value: 'Account Owner', name: "Account Owner"},
		{value: 'Billing', name: "Billing"},
		{value: 'Technical', name: "Technical"},
		{value: 'Other', name: "Other"}
	]);

	app.value('imTypes', [
		{value: 'facebook', name: "Facebook"},
		{value: 'twitter', name: "Twitter"},
		{value: 'skype', name: "Skype"},
		{value: 'hangout', name: "Hangout"},
		{value: 'other', name: "Other"}
	]);

	app.value('phoneTypes', [
		{value: 'work', name: "Work"},
		{value: 'cell', name: "Cell"}
	]);

	app.value('genders', [
		{value: '', name: "All"},
		{value: 'male', name: "Male"},
		{value: 'female', name: "Female"}
	]);

	app.value('taxClasses', [
		{name: "Corporation"},
		{name: "Individual / Sole Proprietor"},
		{name: "Partners / LLC / LLP"},
		{name: "Other"}
	]);

    app.constant('angularMomentConfig', {
        timezone: timeZone
    });

	 //app.constant('moment', require('moment-timezone'));

	// app.constant('angularMomentConfig', {
	// 	preprocess: 'utc' // optional
	// // , timezone: 'Europe/London' // optional
	// });

	app.factory('AmplitudeService', function amplitudeServiceFactrory(){

		let f_this = this;

		if (config && config.amplitudeApiKey) {
			f_this.service = require('amplitude-js/amplitude');

			f_this.service.getInstance().init(config.amplitudeApiKey);
		}

		function isLoaded() {
			return f_this.service ? true : false
		}

		function identify(){
			if (isLoaded()) {
				if (config && config.user) {

					f_this.service.getInstance().setUserId(config.user.id);
					var userProperties = {
						network_id: data_get(config, 'user.network_id', 'unknown'),
						user_type: data_get(config, 'user.user_type', 'unknown'),
						subtype: data_get(config, 'user.subtype', 'unknown'),
						sensitivity: data_get(config, 'user.sensitivity', 0)
					};
					f_this.service.getInstance().setUserProperties(userProperties);
				}
			}
		}

		function track(event_name, event_properties) {
			if (isLoaded()) {
				if (event_properties) {
					f_this.service.logEvent(event_name, event_properties);
				} else {
					f_this.service.logEvent(event_name)
				}
			}
		}

		function setAmplitudeUserProperties(properties) {
			if (isLoaded()){
				f_this.service.getInstance().setUserProperties(properties);
			}
		}

		return {
			isLoaded: isLoaded,
			track: track,
			identify: identify,
            setAmplitudeUserProperties: setAmplitudeUserProperties
		};
	});

    app.config(['$provide', '$locationProvider', '$modalProvider', function ($provide, $locationProvider, $modalProvider) {
        $locationProvider.hashPrefix('');

		$provide.factory('AnalyticsService',['AmplitudeService', function analyticsServiceFactory(AmplitudeService) {
			identify();

			function identify() {
				if (config && config.user) {

					if (AmplitudeService.isLoaded()) {
						AmplitudeService.identify();
					}

				}
			}

			function track(event_name, event_properties) {
				if (AmplitudeService.isLoaded()) {
					AmplitudeService.track(event_name, event_properties)
				}
			}

			function setAnalyticsUserProperties(properties){
				if (AmplitudeService.isLoaded()){
					AmplitudeService.setAmplitudeUserProperties(properties);
				}
			}

			return {
				track: track,
				identify: identify,
                setAnalyticsUserProperties: setAnalyticsUserProperties
			};
		}]);



        $provide.decorator('uiSelectChoicesDirective',['$delegate','uiSelectLimit',function ($delegate,uiSelectLimit) {
            var directive = $delegate[0];
            var compile = directive.compile;
            directive.compile = function(tElement, tAttrs) {
                var link = compile.apply(this, arguments);

                if(tAttrs.hasOwnProperty('showFilter')) {
                    tElement.prepend('<li class="ui-select-filter"><input class="form-control" placeholder="Search Partners" ng-model="$select.search" /></li>')
                }

                if(tAttrs.hasOwnProperty('showLimited') || uiSelectLimit){
                    tElement.append('<li class="ui-select-items" ng-show="(filtered.length-$select.items.length) > 0"><div>{{(filtered.length-$select.items.length)}} item{{(filtered.length-$select.items.length)>1?\'s\':\'\'}} not displayed</div></li>');
                }

                if(tAttrs.hasOwnProperty('showClearAll')) {
                    tElement.append('<li class="ui-select-clear-all" ng-click="' + tAttrs.showClearAll + '">Clear All</li>');
                }

                if(tAttrs.hasOwnProperty('cbClearSelectedArray')) {
                    tElement.append(`<li ng-show="$select.selected.length > 0">
                        <div class="ui-clear-selected">
                            <button ng-click="$select.selected = []">
                                Clear Selected
                            </button>
                        </div>
                    </li>`);
                }

                if(tAttrs.hasOwnProperty('cbOpenModalForAddDomains')) {
                    tElement.append(`<li >
                        <div class="ui-clear-selected">
                            <button ng-click="ctrl.opnMdlForAddDomains()">
                                Add Domain
                            </button>
                        </div>
                    </li>`);
                }

                return function(scope, elem, attrs) {
                    if(attrs.repeat.indexOf('limitTo') < 0 && (attrs.hasOwnProperty('showLimited') || uiSelectLimit)) {
                        var limit = 50;
                        var limitExp = '(limitTo != null ? limitTo : '+limit+')';
                        var limitTo = ' | limitTo: ' + limitExp;
                        if(typeof attrs.limitCheck !== 'undefined') {
                            limitTo = ' | limitTo: ('+attrs.limitCheck+' ? '+limit+' : '+limitExp+')';
                        }
                        attrs.repeat = attrs.repeat.replace(' in ', ' in filtered = (') + ')';
                        attrs.repeat = attrs.repeat.indexOf('track by') < 0 ? attrs.repeat + limitTo : attrs.repeat.replace('track by', limitTo + ' track by');
                    }
                    link.apply(this, arguments);
                };
            };
            return $delegate;
        }]);

		$provide.decorator('uiSelectDirective', ['$delegate', '$timeout', function ($delegate, $timeout) {
			let directive = $delegate[0];

			let originalCompileFn = directive.compile;

			directive.compile = function (tElem, tAttr) {
				let link = originalCompileFn.apply(directive, arguments);

                if(angular.isDefined(tAttr.cbFilterOptions)) {
                    tElem.find('.search-container')
                        .append(`<div ng-include="'${tAttr.cbFilterOptions}'"></div>`);
                }
				return function newLinkFn(scope, elem, attr, ctrl, ccc) {
					let excludedFields = ['$$hashKey', 'is_selected'];

					if (angular.isDefined(tAttr.msExtend)) {
                        elem.querySelectorAll('input.ui-select-search').off('keydown').on('keydown', function(e) {
                            if (e.which === 13){
                                e.preventDefault();
                                e.stopPropagation();
                            } else if(e.which == 27) {
                                ctrl[0].close();
                            }
                        });

						let additionalExcludeFields = [];
						if (angular.isDefined(tAttr.additionalExcludeFields)) {
							try {
								additionalExcludeFields = scope.$eval(tAttr.additionalExcludeFields);
								excludedFields.push(...additionalExcludeFields);
							}catch (e) {
								console.warn(e);
							}
						}
					}
					// fire the originalLinkFn
					let $select = ctrl[0];
					link.apply(directive, arguments);

					scope.$watch('$select.selected', function(n,o){
						if(n != o){
							if(n && Array.isArray(n)){
								for(let i = 0; i < $select.items.length; i++){
									$select.items[i].is_selected =  (findObjectInArray($select.items[i], $select.selected, excludedFields) ? true : false);
								}
							}else if(!angular.isString(n) && !angular.isNumber(n)){
								for(let i = 0; i < $select.items.length; i++){
									try{
										$select.items[i].is_selected = false;
									}catch (e) {
										console.warn('UiSelect elements are not objects. Unable to set is_selected property.');
									}
								}
							}
						}
					});

					if (angular.isDefined(tAttr.cbExtend)) {
						if (scope.$$listeners.hasOwnProperty('uis:select') >= 0) {
							delete scope.$$listeners['uis:select'];
						}

						scope.$on('uis:select', function (event, item) {
							if(!$select.selected){
								$select.selected = [];
							}

							let selected_el_exists = findObjectInArray(item, $select.selected, excludedFields);

							if((selected_el_exists && item.is_selected) || (!selected_el_exists && !item.is_selected)){
								item.is_selected = !item.is_selected;
							}


							$select.selected = $select.selected.filter(function (el) {
								if (isEqualObjects(el, item, excludedFields)) {
									return false;
								}
								return true;
							});


							if(item.is_selected){
								$select.selected.push(item);
							}

							if (angular.isDefined(tAttr.appExtend)) {
								if ($select.selected.length > 1 && $select.selected[0].hasOwnProperty('name') && $select.selected[0].name == 'None - I do not have a tracking platform') {
									$select.selected = $select.selected.filter(function (item) {
										return item.name != 'None - I do not have a tracking platform';
									})
								} else if ($select.selected.find(x => x.name == 'None - I do not have a tracking platform')) {
									$select.selected = $select.selected.filter(function (item) {
										if (item.name == 'None - I do not have a tracking platform') {
											return item;
										} else {
											item.is_selected = false;
										}
									});
								}
							}

							if (angular.isDefined(tAttr.msExtend)) {

								if ($select.selected.find(x => x.type == 'other')) {
									$select.selected = $select.selected.filter(function(item) {
										return item.type != 'other';
									});
								}

								var mainItem = $select.selected.find(function (item) {
									return item.type == 'main';
								});

								var primaryItems = $select.selected.filter(function (item) {
									return (item.type == 'primary' && isUndefinedOrNull(item.selectedFlag));
								});

								var secondaryItems = $select.selected.filter(function (item) {
									return (item.type == 'secondary' && isUndefinedOrNull(item.selectedFlag));
								});

								var secondMainItem = null;

								if ($select.selected.length > 1) {
									secondMainItem = $select.selected.find(function (y) {
										if (y.type == 'main' && y.id != mainItem.id) {
											return y;
										}
									});
								}

								// deselecting main
								if ($select.selected.length >= 1 && !mainItem && primaryItems.length < 1 && secondaryItems.length < 1) {
									for (let x = 0; x < $select.selected.length; x++) {
										if (!isUndefinedOrNull($select.selected[x].selectedFlag)) {
											delete $select.selected[x].selectedFlag;
										}
									}
									$select.selected = [];
								}

								// deselecting primary
								if ($select.selected.length >= 1 && primaryItems.length < 1 && secondaryItems.length < 1) {
									let secondaryForRemoval = [];
									for (let z = 0; z < $select.selected.length; z++) {
										if ($select.selected[z].type == 'secondary' && !$select.selected.find(item => item.type == 'primary' && item.primary_id == $select.selected[z].primary_id)) {
											secondaryForRemoval.push($select.selected[z].id);
										}
									}

									secondaryForRemoval.forEach(element => $select.selected = $select.selected.filter(function (a) {
										if ((a.type == 'secondary' && a.id != element) || a.type != 'secondary') {
											return a;
										} else {
											delete a.selectedFlag;
										}
									}));
								}

								// selecting different main
								if (!isUndefinedOrNull(secondMainItem)) {

									for (let x = 0; x < $select.selected.length; x++) {
										if (!isUndefinedOrNull($select.selected[x].selectedFlag)) {
											delete $select.selected[x].selectedFlag;
										}
									}
									$select.selected = $select.selected.filter(function (x) {
										if (x.type == 'main' && x.id == secondMainItem.id) {
											item.selectedFlag = true;
											return item;
										}
									});

									primaryItems = [];
									secondaryItems = [];
									mainItem = null;
								}

								if (primaryItems.length > 0) {

									for (let i = 0; i < primaryItems.length; i++) {
										primaryItems[i].selectedFlag = true;
										if (!$select.selected.find(x => x.id == primaryItems[i].main_id && x.type == 'main')) {
											let mItem = {
												'id': primaryItems[i].main_id,
												'name': primaryItems[i].main_name,
												'type': 'main',
												'is_selected': true,
												'selectedFlag': true,
												'expanded': true
											};
											$select.selected = $select.selected.filter(function (y) {
												if ((y.type == 'main' && y.id == mItem.id) || (y.type != 'main' && y.main_id == mItem.id)) {
													return y;
												} else {
													delete y.selectedFlag;
												}
											});
											$select.selected.push(mItem);
										}
									}
								}

								if (secondaryItems.length > 0) {
									for (let j = 0; j < secondaryItems.length; j++) {
										secondaryItems[j].selectedFlag = true;
										if (!$select.selected.find(z => z.id == secondaryItems[j].main_id && z.type == 'main')) {
											let mItem = {
												'id': secondaryItems[j].main_id,
												'name': secondaryItems[j].main_name,
												'type': 'main',
												'is_selected': true,
												'selectedFlag': true,
												'expanded': true
											};
											$select.selected = $select.selected.filter(function (y) {
												if ((y.type == 'main' && y.id == mItem.id) || (y.type != 'main' && y.main_id == mItem.id)) {
													return y;
												} else {
													delete y.selectedFlag;
												}
											});
											$select.selected.push(mItem);
										}

										if (!$select.selected.find(w => w.primary_id == secondaryItems[j].primary_id && w.type == 'primary')) {
											let primItem = {
												'primary_id': secondaryItems[j].primary_id,
												'name': secondaryItems[j].primary_name,
												'type': 'primary',
												'is_selected': true,
												'selectedFlag': true,
												'expanded': true,
												'main_id': secondaryItems[j].main_id,
												'main_name': secondaryItems[j].main_name,
											};
											$select.selected.push(primItem);
										}
									}
								}
							}

							let locals = {};
							locals[$select.parserResult.itemName] = item;

							$timeout(function () {
								$select.onSelectCallback(scope, {
									$item: item,
									$model: $select.parserResult.modelMapper(scope, locals)
								});
							});
						});

						scope.$on('uis:refresh', function (event, item) {
							let ngModel = scope.$eval(attr['ngModel']);
							if (ngModel) {

								$select.selected = ngModel;

								$select.items = $select.items.map(function (el) {
										if (findObjectInArray(el, $select.selected, excludedFields)) {
											el.is_selected = true;
										} else {
											el.is_selected = false;
										}
										return el;
								});
							}
						});

					}
				};
			};

			return $delegate;
		}]);

        angular.extend($modalProvider.defaults, {
            zIndex: 2000
        });
    }]);
    app.filter('propsFilter', function() {
        return function(items, props) {
            let out = [];
            if (!angular.isArray(items)) {
                return items;
            }
            items.forEach(function(item) {
                let found = Object.keys(props)
                    .some( prop => {
                        let text =  props[prop].toLowerCase();
                        return item[prop] ? item[prop].toString().toLowerCase().indexOf(text) !== -1 : false;
                    });
                if(found){
                    out.push(item)
                }
            });
            return out;
        }
    });
	app.run(['$locale', '$rootScope', '$templateCache', '$cacheFactory', 'Resource', 'AnalyticsService',
		function ($locale, $rootScope, $templateCache, $cacheFactory, Resource, AnalyticsService) {

		Resource.extend({
			initialize: function () {
				var options = this.options;
				var _super = options.extendPromise || angular.noop;

				options.extendPromise = function (promise) {
					promise.rawTo = function (objectOrArray, property) {
                        promise.to(objectOrArray, property, true);
                        return promise;
                    };

					_super(promise);
				}
			}
		});

        $rootScope.cache = $cacheFactory('cacheId');

		$locale.DATETIME_FORMATS.SHORTDAY = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

		function callGa () {
			if (typeof ga == 'undefined') {
				console.log("Google Analytics not loaded");

				setTimeout(callGa, 2000);
			}
			else {
				console.log('ga', 'send', 'event', 'signup', config.user.user_type + '_signup');

				if (config.user) {
					ga('send', 'event', 'signup', config.user.user_type + '_signup');
				}
			}
		}

		if (typeof config !== 'undefined' && config.welcome) {
			callGa();
		}


		if (typeof Highcharts !== 'undefined' && Highcharts !== null) {
			var utcOffset = (new Date()).getTimezoneOffset();
			var etcOffset = -240;

			var offset;
			if (utcOffset > 0) {
				offset = utcOffset * -1;
			}
			else {
				offset = utcOffset + etcOffset;
			}

			// console.log( offset );
			Highcharts.setOptions({
				global: {
					//timezoneOffset: offset
				}
			});

			Highcharts.setOptions({
				colors: [
					'#FF8900',
					'#E5592E',
					'#47A4ED',
					'#00BF9D',
					'#8DD7FC',
					'#FF0001',
					'#007892',
					'#C40075',
					'#743CDF',
					'#F3C500',
					'#1F5B83',
					'#191726'
				]
			});

		}

		/**
		 * Close all open modals
		 */
		var modals = [];

		$rootScope.$on('modal.show', function (e, $modal) {
			if (modals.indexOf($modal) === -1) {
				modals.push($modal);
			}
		});

		$rootScope.$on('$locationChangeStart', function () {
			if (modals.length) {
				angular.forEach(modals, function ($modal) {
					if ($modal.$isShown) {
						$modal.hide();
					}
				});

				modals = [];
			}
		});
		$rootScope.isFeatureEnabled = window.isFeatureEnabled;
		$rootScope.isFeatureEnabledForUser = window.isFeatureEnabledForUser;

			$rootScope.$on('$stateChangeSuccess',
				function (event, toState, toParams, fromState, fromParams) {
					AnalyticsService.track('new_state', {
						name: toState.name,
						templateUrl: toState.templateUrl,
						title: toState.title,
						url: toState.url
					})
				}
			);

		}]);

	app.filter('dateStringToTimestamp', function () {
		return function (d) {
			if (d && typeof d == 'string') {
				if (d.indexOf('T') !== -1) {

					return Date.parse(d.split(' ').join('T'));
				} else if (d[10] === ' ') {
					d = d.substr(0, 10) + "T" + d.substr(11, d.length);
					var dd = new Date(d);
					dd.setMinutes( dd.getMinutes() + (new Date().getTimezoneOffset() > 0 || new Date().getUTCDate() != new Date().getDate() ? dd.getTimezoneOffset() : (dd.getTimezoneOffset() * -1) ) ); // ispravniji nacin
					return dd;
				}
			}
			return d;
		};
	});

	app.filter('timestampToDate', function () {
		return function (d) {
			return new Date(d * 1000);
		};
	});


	app.filter('dateStringToDate', function () {
		return function (dateString) {
			var parsed = '';
			if (!dateString) {
				return parsed;
			}
			// yyyy-mm-dd hh:mm:ss
			if (dateString[4] === '-' && dateString[10] === ' ' && dateString[13] === ':') {
				return new Date(dateString.split(' ').join("T"));
			}
			if (dateString[7] === '-' && dateString.length) {
				parsed = new Date(dateString + "T00:00:00.000Z");
			}

			return parsed;
		};
	});

	app.filter('tsToTimezone', function () {

		return function (d, tzOffset) {
			d = new Date(d);
			var timezoneOffset = tzOffset ? d.getTimezoneOffset() + (tzOffset*60) : d.getTimezoneOffset();
			var utc = d.getTime() + (timezoneOffset * 60000);
			var nd = new Date(utc);//+ (3600000*config.timeZoneOffset));
			return nd.getTime() / 1000;// $filter('date')(nd, 'yyyy-MM-dd');
		};
	});

	app.filter('hourRangeRestrict', function () {
		return function (hours, hour) {
			var returns = [];
			if (hour) {
				for (var i = 0; i < hours.length; i++) {
					if (hours[i].value >= hour) {
						returns.push(hours[i]);
					}
				}
				return returns;
			}
			else {
				return hours;
			}
		};
	});

	app.filter('minuteRangeRestrict', function () {
		return function (minutes, hour_from, hour_to, from_minute) {
			var returns = [];
			if (hour_from && hour_to && hour_from == hour_to && from_minute) {
				for (var i = 0; i < minutes.length; i++) {
					if (minutes[i].value >= from_minute) {
						returns.push(minutes[i]);
					}
				}
				return returns;
			}
			else {
				return minutes;
			}
		};
	});

	app.filter('truncate', function() {
		return function (input, characters) {
			characters = characters || 10;

			if (input) {
				var string = input+'';
				return string.substr(0, characters) + '...';
			}
		};
	});

	app.filter('slice', function() {
		return function(arr, start, end) {
			return (arr || []).slice(start, end);
		};
	});

	app.filter('filterByIdOrFullName', function(){
		return function(collection, search)
		{
			if(search)
			{
				search = search.toLowerCase();
				var returns = [];
				for(var i = 0; i < collection.length; i++)
				{
					if(typeof collection[i].id == "undefined" || typeof collection[i].full_name == "undefined")
					{
						continue;
					}

					if((collection[i].id+"").toLowerCase() == search || collection[i].full_name.toLowerCase().indexOf(search) >= 0)
					{
						returns.push(collection[i]);
					}
				}

				return returns;
			}

			return collection;
		}
	});

	app.filter('filterOr', function(){
		return function(collection, search)
		{
			if(arguments.length <= 2)
			{
				return collection;
			}

			var j = 2;
			var found = false;

			if(search)
			{
				search = search.toLowerCase();
				var returns = [];
				for(var i = 0; i < collection.length; i++)
				{
					for(j = 2; j < arguments.length; j++)
					{
						if(collection[i].hasOwnProperty(arguments[j]) && typeof collection[i][arguments[j]] != "undefined" && ((collection[i][arguments[j]]+"").toLowerCase().indexOf(search) >= 0))
						{
							found = true;
							break;
						}
					}

					if(!found)
					{
						continue;
					}

					returns.push(collection[i]);
					found = false;
				}

				return returns;
			}

			return collection;
		}
	});

	function filterCollection(collection, search, search_properties, config_params) {
		if (!search) {
			return collection;
		}
		if (!(typeof search === 'string' || (search instanceof String))) {
			console.warn('Filter extend search value need to be string. Currently using: ', (typeof search));
			return collection;
		}
		if (search_properties != undefined && !Array.isArray(search_properties)) {
			console.warn('Filter search properties list must be array. Current value of search properties list: ', search_properties);
			return collection;
		}

		let ret = [];

		if (collection) {
			for (let i = 0; i < collection.length; i++) {

				if (!search_properties || search_properties.length == 0) {
					search_properties = Object.keys(collection[i]);
					if (search_properties.indexOf('$$hashKey'))
						delete search_properties['$$hashKey'];
				}

				for (let l = 0; l < search_properties.length; l++) {

					if (collection[i].hasOwnProperty(search_properties[l]) && typeof collection[i][search_properties[l]] != "undefined") {
						let current_object_property_value = (collection[i][search_properties[l]] + "");

						if (!config_params || (config_params && !config_params.case_sensitive)) {
							if (search.toLowerCase !== undefined)
								search = search.toLowerCase();
							current_object_property_value = current_object_property_value.toLowerCase();

							if (search.normalize !== undefined) {
								search = search.normalize("NFKD");
							}
							current_object_property_value = current_object_property_value.normalize("NFKD");

							if (search.replace !== undefined) {
								search = search.replace(/[\u0300-\u036F]/g, "");
							}
							current_object_property_value = current_object_property_value.replace(/[\u0300-\u036F]/g, "");
						}

						if ((current_object_property_value).indexOf(search) >= 0) {
							ret.push(collection[i]);

							break;
						}
					}
				}
			}
		}

		return ret;
	}

	app.filter('filterExtend', function () {
		return function (collection, search, search_properties, config_params) {
			return filterCollection(collection, search, search_properties, config_params);
		}
	});

	app.filter('filterExtendDtt', function () {
		return function (collection, search, search_properties, config_params) {

			let found_matches = filterCollection(collection, search, search_properties, config_params);

			if (!Array.isArray(found_matches)) {
				return found_matches;
			}

			let ret = collection.filter(function(item){
				let main_subitem_found = item.type === 'main' && (found_matches.findIndex(x => x.main_id === item.id) >= 0);
				let primary_subitem_found = item.type === 'primary' && (found_matches.findIndex(x => x.primary_id === item.primary_id) >= 0);
				return found_matches.indexOf(item) >= 0 || main_subitem_found || primary_subitem_found;
			});

			return ret;
		}
	});

	app.filter('nl2br', ['$sce', function ($sce) {
		return function (text) {
			return text ? $sce.trustAsHtml(text.replace(/\n/g, '<br/>')) : '';
		};
	}]);

	app.filter('bytes', function() {
		return function(bytes, precision) {
			if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
			if (typeof precision === 'undefined') precision = 1;
			var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
				number = Math.floor(Math.log(bytes) / Math.log(1024));
			return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
		}
	});

	app.filter('lists', function() {
		return function(arr, key, val) {
			if(angular.isArray(arr)) {
				var obj = {};
				var tmpArr = arr.map(function(v) {
					obj[v[key]] = v[val];
					return v[key];
				});
				return typeof val != 'undefined' ? obj : tmpArr;
			}

			return arr;
		}
	});

	app.config(['$provide', function ($provide) {
		$provide.decorator('dateFilter', ['$delegate', function ($delegate) {
			var srcFilter = $delegate;

			var extendsFilter = function (date, format, timezone) {

                if(typeof date == 'undefined') return date;
                if(typeof date == 'number') date = new Date(date * 1000);
				if(!date) return date;
                if (!(date instanceof Date) && date[10] === ' ') {
                    date = Date.parse(date.split(' ').join('T') + (timezone ? 'Z' : ''));
                }

				if(typeof date == 'number') {
					date = new Date(date);
				}

				if (date && !date.parsed && timezone) {
					var utcOffset = date.getTimezoneOffset();

					if (!isNaN(utcOffset)) {
						date.setMinutes(date.getMinutes() + (utcOffset));
					}

					date.parsed = true;
				}

				return srcFilter.apply(this, [date, format]);
			}
			return extendsFilter;
		}])
	}]);

	app.directive('errorMessage', ['$rootScope', function ($rootScope) {

		$rootScope.$discardMessage = function () {
			$rootScope.$message = null;
		};

		return {
			restrict: 'E',
			template: (
				'<div class="alert alert-danger" ng-if="$message">' +
				'<div class="close-alert-msg pull-right"><i class="ti-close" ng-click="$discardMessage()"></i></div>' +
				'{{ $message }}' +
				'</div>'
			),
			link: function (s, e, a) {

			}
		}
	}]);

	app.directive('errors', ['$timeout', function ($timeout) {
		return {
			restrict: 'E',
			require: 'ngModel',
			scope: {
				ngModel: '='
			},
			template: (
				'<div class="clearfix" ng-if="ngModel">' +
                    '<div class="row">' +
                        '<div class="col-xs-12">' +
                            '<div class="alert alert-danger">' +
                                '<ul class="list-unstyled">' +
                                    '<li ng-repeat="e in err">{{ e.join(", ") }}</li>' +
                                '</ul>' +
                            '</div>' +
                        '</div>' +
					'</div>' +
				'</div>'
			),
			link: function (scope, element, attrs) {
				scope.err = [];
				scope.$watch('ngModel', function(value) {
					if(!$.isEmptyObject(value)) {
						if (typeof value.message != 'undefined') {
							scope.err = [[value.message]];
						} else if(typeof value.error != 'undefined' && typeof value.error.message != 'undefined') {
							scope.err = [[value.error.message]];
						} else {
							scope.err = value;
						}

						$timeout(function() {
							scope.err = null;
							scope.ngModel = null;
						}, 10000);
					}
				});
			}
		}
	}]);

	app.directive('stringToNumber', function() {
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				ngModel.$parsers.push(function(value) {
					return '' + value;
				});
				ngModel.$formatters.push(function(value) {
					return parseFloat(value, 10);
				});
			}
		};
	});

    app.directive('stringToNumberRound', function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(value) {
                    return '' + value;
                });
                ngModel.$formatters.push(function(value) {
                    let num = parseFloat(value);
                    return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
                });
            }
        };
    });


    app.directive('navTabs', ['$timeout', '$templateCache', '$compile', function ($timeout, $templateCache, $compile) {
		return {
			restrict: 'C',
			link: function (scope, element, attrs) {
				if(element.hasClass('no-truncate')) {
					return;
				}

				var $lis = element.find('li');

				if ($lis.length > 6 && !element.has('.truncated').length) {
					var tr = element.find('.truncated');
					if(tr.length < 1){
						var temp = $templateCache.get('truncated-dropdown-tabs.html');
						tr = element.append(temp).find('.truncated');
					}

					var tabs = $lis.filter(':gt(4)').not('.no-truncate-link');
					var dropdownTabs = element.find(".truncated-ul");
					dropdownTabs.empty();

					if ($lis.length > 6) {
						tr.show();
					}

					let cloned_tabs = tabs.clone();

					dropdownTabs.append(cloned_tabs);
					$compile($(dropdownTabs).contents())(scope);

					tabs.hide();

				}
			}
		};
	}]);

	app.directive('dirClipboardJs', function(){
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				new ClipboardJS(element[0]);
			}
		}
	});

	app.directive('autoFocus', function($timeout) {
		return {
			restrict: 'AC',
			link: function(_scope, _element) {
				$timeout(function(){
					_element[0].focus();
				}, 0);
			}
		};
	});

	app.controller('AppUiController', ['$rootScope', '$scope', '$state', 'config', '$timeout', 'paginationOptions', 'dateRangeOptions', '$filter', 'uiSelectLimit', '$cookies', '$http',
        function ($rootScope, $scope, $state, config, $timeout, paginationOptions, dateRangeOptions, $filter, uiSelectLimit, $cookies, $http) {

        $scope.timezone = 'EST';

        $scope.cookiesService = $cookies;

        $rootScope.timezone = 'EST';

        $scope.mediumDateFormat = 'MM-dd-yyyy';

        $rootScope.mediumDateFormat = 'MM-dd-yyyy';

        $scope.longDateFormat = 'MM-dd-yyyy h:mm a';

        $rootScope.longDateFormat = 'MM-dd-yyyy h:mm a';

        $scope.stdDateFormat = "dd MMM yyyy h:mm a";

        $rootScope.stdDateFormat = "dd MMM yyyy h:mm a";

		$scope.timeOnlyFormat = 'h:mm a';

		$scope.paginationOptions = paginationOptions;

		$scope.dateRangeOptions = dateRangeOptions;

		$scope.isEmpty = $.isEmptyObject;
		$scope.shield_readonly = true;

		if (config && config.user && config.user.role && config.user.role.other && config.user.role.other.adjust_shield
		    && config.user.role.other.adjust_shield.full.indexOf(config.user.id) >= 0) {
			$scope.shield_readonly = false;
		}

		$scope.limitTo = uiSelectLimit;
		$rootScope.limitTo = uiSelectLimit;

		$scope.setCookie = function (name, value, days_alive) {
			value = angular.toJson(value);
			var date = new Date();
			date.setTime(date.getTime() + (days_alive*24*60*60*1000));
			var expires = "expires="+date.toUTCString();
			return document.cookie = "ui-cookie=" + value + "; " + expires;
		};

		$scope.visitUrl = function(destination_url) {

            if(!destination_url) {
                return;
            }

            var arr = ['{linkid}', '{clickid}', '{subid1}', '{subid2}', '{subid3}', '{subid4}', '{subid5}', '{subid6}',
				'{creative_id}', '{source_id}', '{pid}'];

            arr.forEach(function (a) {
                var count = a.length;
                var offset = destination_url.indexOf(a);

                if (offset > -1) {
                    destination_url = destination_url.substr(0, offset) + destination_url.substr(offset + count);

                    //edge case
                    if(destination_url.indexOf(a) != -1) {
                        destination_url = destination_url.substr(0, destination_url.length - count);
                    }
                }
            });

            return destination_url;
        };
		$rootScope.visitUrl = $scope.visitUrl;
		$rootScope.checkAdvertiserTier = function () {
			return (config.user.was_admin && !isUndefinedOrNull(config.use_marketer_ui)) || config.user.advertiser_tier == 'prime' || isUndefinedOrNull(config.user.advertiser_tier);
		}

		$scope.getCookie = function (name){
			var value = false;
			var cookies = document.cookie.split(';');
			var i,x,y;
			for (i=0;i<cookies.length;i++) {
				x=cookies[i].substr(0,cookies[i].indexOf("="));
				y=cookies[i].substr(cookies[i].indexOf("=")+1);
				x=x.replace(/^\s+|\s+$/g,"");
				if (x == name) {
					value = unescape(y);
				}
			}
			if(value){
				return angular.fromJson(value);
			} else {
				return;
			}
		}

		var cookie = $scope.getCookie('ui-cookie');

		if(cookie){
			$scope.cookie = cookie;
		} else {
			$scope.cookie = {show_graphs: false, aside_closed: true};
		}
		$scope.$watch('cookie', function (n, o) {
			$scope.setCookie('ui-cookie', n, 360);
		}, true);

		if (typeof config !== 'undefined' && config.user) {
			$scope.user = config.user;
		}

		$scope.hasError = function (ngModel) {

			if (!ngModel) {
				return;
			}

			if (ngModel.$viewValue && ngModel.$viewValue.length > 0) {
				ngModel.$touched = true;
			}

			if (ngModel.$touched === false) {
				return false;
			}

			var hasErrors = false, k;
			for (k in ngModel.$error) {
				hasErrors = true;
				break;
			}

			return hasErrors;
		};

		$scope.theTitle = '';

		$scope.setTitle = function(s) {
			$scope.theTitle = s;
		};

		$scope.$on("$stateChangeSuccess", function (event, curr) {

			var stateName = curr.name;
			var stateNameClass = (stateName + '-state').replace(/\./g, '-');
			window.stateName = stateName;

			$scope.stateName = stateName;
			$scope.stateNameClass = stateNameClass;

			if (curr.title) {
				$scope.theTitle = (typeof curr.title == 'function') ? curr.title() : curr.title;
			}

			if (curr.subtitle) {
				$scope.theSubtitle = (typeof curr.subtitle == 'function') ? curr.subtitle() : curr.subtitle;
			}
			else {
				$scope.theSubtitle = "";
			}
            if(typeof curr.scipScroll == undefined || !curr.scipScroll) {
                setTimeout(function () {
                    $("html, body").animate({scrollTop: "0px"}, 400);
                }, 100);
            }
            setTimeout(function () {
                var title = angular.copy(String($scope.theTitle).replace(/<[^>]+>/gm, ''))
                let network = config.user.network || {};

				if(config.user.is_ignite){
					title += ' - Perform[cb] OPM';
				}else{
					switch (network.alias){
						case 'adperio':
							title += ' - Perform[cb]';
							break;
						default:
							title += ' - Perform[cb] The #1 Performance Exchange in the World';
					}
				}

                $('title').text(title);
            });

            //user button toolbar timeout
            setTimeout(function () {
                $('.user-btn-toolbar').removeClass("hide-me");
            }, 800);

            //floatThead-track
            setTimeout(() => $scope.reflowFloatTrack(), 100);
            //custom scrollbar - responsive table
            setTimeout(() => $scope.reflowBaronScrollbar());

            // Fixes for page layout in case if content comes from React Iframe
            setTimeout( function () {
                if ($('#admin-dashboard-iframe').length > 0) {
                    $('body').addClass('admin-dashboard-body')
                } else {
                    $('body').removeClass('admin-dashboard-body')
                }
            })

		});

		function reflowTracker() {
            var parent = $('[floating-table]');
            if (parent.length)  {
                if(! parent.find('.floatThead-track').length){
                    parent.closest('.table-responsive-vertical').append("<div class='floatThead-track'></div>");
                }
                if ($('.page-tabs').length)  {
                    $('.floatThead-track').css('top', '98px');
					$('.leap  .floatThead-track').css('top', '108px');
					$('.leap-admin  .floatThead-track').css('top', '98px');
                }
            } else {
                $('.floatThead-track').remove();
            }
        }
		//custom scrollbar on tables
		function baronScrollbar() {
			$( ".scroll__track" ).hide();
			if ($('.table-responsive-vertical').length) {
				$( ".scroll__track" ).show();
				//console.log("table-responsive-vertical is here")
			}
			if(baron._instances.length > 0) baron._instances.length = 0;
			baron({
				root: 'body',
				scroller: '.table-responsive-vertical:not(.hourly-responsive)',
				bar: '.scroll__bar',
				scrollingCls: '_scrolling',
				draggingCls: '_dragging',
				cssGuru: true,
				impact: 'clipper',
				direction: 'h'
			});
		}

		$scope.reflowFloatTrack = (wait = 100) => {
            setTimeout(() => reflowTracker(), wait);
        }
        $scope.reflowBaronScrollbar = (wait = 100) => {
            setTimeout(() => baronScrollbar(), wait);
        }

		$scope.parseToDollars = function (value, step, operation, round, noformat, low, low_positive, empty) {
			if (typeof empty !== 'undefined' && empty && (typeof value == 'undefined' || value == null || value == "")) {
				return "";
			}
			if (typeof noformat == 'undefined') {
				noformat = false;
			}
			noformat = noformat == true ? true : false;
			if (typeof low_positive == 'undefined') {
				low_positive = false;
			}
			if (typeof low == 'undefined') {
				low = 0;
			} else {
				low = low + "";
				low = low.replace(/[^0-9.]/g, "");
				low = parseFloat(low);
				low = low_positive ? Math.abs(low) : -Math.abs(low);
			}

			value = window.getParsedValue(value, low);

			var add = parseFloat(step) || 0;

			if (operation == 'add') {
				var result = parseFloat(value) + parseFloat(add);
			} else {
				var result = parseFloat(value) - parseFloat(add);
			}

			result = Math.round(result * Math.pow(10, round)) / Math.pow(10, round);

			if (result < low) {
				if (noformat) {
					return low;
				}

				return $filter('currency')(low, '$', round);
			}

			if (noformat) {
				return result;
			}

            return $filter('currency')(result, '$', round);
		};

		$rootScope.parseToDollars = $scope.parseToDollars;

		$scope.budgetValueTypeChanged = function(item, campaign) {
			if(campaign.budget_value_type == campaign.budget_value_type_tmp) {
				return;
			}

			campaign.budget_value_type = campaign.budget_value_type_tmp;
			let test_budget_exists = typeof campaign.link_test_budget_value !== 'undefined';

			var budget = window.getParsedValue(campaign.budget, 1);
			var link_test_budget_value = window.getParsedValue(campaign.link_test_budget_value, 1);
			if(typeof campaign.is_revshare != 'undefined' && campaign.is_revshare == true && campaign.revshare.length) {
				var payout = 0;
				for (var i = 0; i < campaign.revshare.length; i++) {
					if (campaign.revshare[i].value > payout) {
						payout = campaign.revshare[i].value;
					}
				}
			} else {
				var payout = window.getParsedValue(campaign.pricing_model == 'cpa' ? campaign.cpa : campaign.max_bid);
			}

			if(item.value == 1) {
				campaign.budget = budget/payout;
				if(test_budget_exists) {
					campaign.link_test_budget_value = link_test_budget_value / payout;
				}
			} else {
				campaign.budget = payout * budget;
				if(test_budget_exists) {
					campaign.link_test_budget_value = link_test_budget_value * payout;
				}
			}

			campaign.budget = $scope.parseToDollars(campaign.budget, 0, 'add', 0, campaign.budget_value_type, 1, true);
			if(test_budget_exists) {
				campaign.link_test_budget_value = $scope.parseToDollars(campaign.link_test_budget_value, 0, 'add', 0, campaign.budget_value_type, 1, true);
			}
		};

		$rootScope.budgetValueTypeChanged = $scope.budgetValueTypeChanged;

		$scope.getCampaignErrorMessages = function (response) {
			var campaign_error = [];
			campaign_error.push("Failed to save campaign");
			if (typeof response.error !== 'undefined' && typeof response.error.message !== 'undefined') {
				campaign_error.push(response.error.message);
			} else if (typeof response.code !== 'undefined' && typeof response.message !== 'undefined') {
				campaign_error.push(response.message);
			} else {
				angular.forEach(response, function (value, key) {
					if (typeof value[0] !== 'unefined') {
						this.push(value[0]);
					}
				}, campaign_error);
			}
			return campaign_error;
		};



		$scope.offsetByDays = function (date, offset) {
			offset = offset === 0 ? offset : offset || 1;
			if (date && date instanceof Date) {
				var copy = new Date(date);
				copy.setDate(copy.getDate() + offset);
				return copy;
			}
		}

	/**
	 * This is workaround for missing JWT token for React application.
	 * New jwt token is created, sent to React app through specific iframe (postMessage)
	 * After sending jwt token to React app state needs to be reloaded for reloading React page
	 */

	$scope.createJwtTokenAndReload = function(iframeId, tokenName, user, state){
		fetch(process.env.APP_URL+"/api/v1/users/create_token")
			.then(response => response.text())
			.then(data => {
				let react_data = $scope.getReactData(tokenName, user)
				document.getElementById(iframeId).contentWindow.postMessage(react_data, process.env.REACT_APP_URL)
				state.reload(state.current.name);
			});
	}

	/**
	 * Reading JWT token from cookies by name
	 */

	$scope.getTokenFromCookie = function (tokenName){
		let token;
		document.cookie.split(";").map(item => {
			if (item.includes(tokenName)) {
				token = item.slice(item.indexOf("=") + 1);
			}
		});
		return token;
	}

	/**
	 * JSON for react app
	 */

	$scope.getReactData = function (tokenName, user){
		return {
			"id": 1,
			"token": $scope.getTokenFromCookie(tokenName),
			"user": user,
            "config" : config
		}
	}

		$scope.asideToggle = function () {
            $scope.cookie.aside_closed = !$scope.cookie.aside_closed;
            // $scope.$broadcast('ui_aside_toggle', $scope.cookie.aside_closed);
            $scope.$evalAsync(function() {

                setTimeout(function() {
                    window.dispatchEvent(new Event('resize'));
					$('slick').slick('setPosition');
                    // v.getHighcharts().reflow()
                }, 10)
            });
        }

        $scope.isAppNetwork = function (network = 'clickbooth') {
		    let { alias = 'clickbooth' } = config.user.network;
		    return alias === network;
        }

        $scope.setNewDesignFeature = function (cancelReq) {
			if(isFeatureEnabled('partner-new-design')) {
                let partner_new_design = 'partner_new_design' + config.user.id;
                $cookies.put(partner_new_design, true);
			}

			if(cancelReq) {
				$scope.cancelRequests();
			}
		}

		$scope.cancelRequests = function () {
			let requests = $http.pendingRequests;

			for (let i = 0; i < requests.length; i++) {
				if( ! isUndefinedOrNull(requests[i].timeout)) {
					requests[i].timeout.deferred.resolve()
				}
			}
		};
	}]);

	// header on home page
	$(function () {
		if ($('.home-navbar').length > 0) {
			$(window).on("scroll", function () {
				if ($(this).scrollTop() > 150) {
					$(".home-navbar").addClass("black");
				}
				else {
					$(".home-navbar").removeClass("black");
				}
			});
		}



		FastClick.attach(document.body);

	});

	function detectBrowser() {
		if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
			return 'opera-userAgent';
		} else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
			return 'chrome-userAgent';
		} else if(navigator.userAgent.indexOf("Safari") != -1) {
			return 'safari-userAgent';
		} else if(navigator.userAgent.indexOf("Firefox") != -1 ){
			return 'firefox-userAgent';
		} else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
			return 'ie-userAgent';//crap
		} else {
			return 'unknown-userAgent';
		}
	}
	$('body').addClass(detectBrowser());

	app.run(['$rootScope', '$window', function ($rootScope, $window) {
		$rootScope.goBack = function () {
			$window.history.back();
//			if ($rootScope.$$phase) {
//				$rootScope.$apply();
//			}
		}
	}]);

	app.directive('backButton', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element.bind('click', scope.goBack);

			}
		};
	});


	if (window.config) {
		var k,
			statuses = [{name: 'All Statuses', value: 0}];

		for (k in config.transactionStatuses) {
			statuses.push({name: config.transactionStatuses[k], value: k});
		}
		app.value('transactionStatuses', statuses);

		var types = [], k;
		for (k in config.transactionCurrencyTypes) {
			types.push({name: config.transactionCurrencyTypes[k], value: k});
		}
		app.value('currencyTypes', types);

		var t = [{name: 'All Types', value: 0}], k;
		for (k in config.transactionTypes) {
			t.push({name: config.transactionTypes[k], value: k});
		}
		app.value('transactionTypes', t);
	}

	app.directive('unsigned', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element.on('keyup keydown', function (e){
					value = e.target.value;
					if(value < 0){
						e.target.value = 0;
					}
				});
			}
		};
	});

	app.directive('showGraph', function () {
		return {
			restrict: 'A',
			template: "<i class=\"ti-bar-chart\"></i>",
			link: function (scope, element, attrs) {
				element.on('click', function(){
					scope.$parent.$apply(function(){
						var cookie = scope.$parent.cookie;
						if(typeof cookie["show_graphs"] == 'undefined'){
							cookie.show_graphs = true;
						} else {
							cookie.show_graphs = ! cookie.show_graphs;
						}
						scope.$parent.cookie = cookie;
					});
				});
			}
		};
	});
	app.directive('featureFlag', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				if( ! scope.isFeatureEnabled(attrs.featureFlag)){
					element.hide();
				}
			}
		};
	});
	app.directive('linkTestBudget', function () {
		return {
			restrict: 'E',
			scope: {
				budget: "="
			},
			template: (
				'<div ng-if="budget.type !== \'DISABLED\'">' +
					'<span class="budget" ng-if="budget.flag == 0">{{ budget.value | currency:$:2 }}<small>{{ budget.type }}</small></span>' +
					'<span class="budget" ng-if="budget.flag == 1">{{ budget.value | number:"0" }} conv<small>{{ budget.type }}</small></span>' +
				'</div>' +
				'<span ng-if="!budget || budget.type == \'DISABLED\'">-</span>'

			),
			link: function (s, e, a) {

			}
		}
	});

	app.directive('tagsList', ['TagsService', 'TagPropertySettingService', function(TagsService, TagPropertySettingService){
		return {
			restrict: 'E',
			scope:{
				propertyType: '@',
				propertyId: '=',
			},
			templateUrl: template('common-directive-templates/tags-list'),
			link: function(scope, element, attr){
				scope.directive_data = {};

				scope.$watch(function(){
					return scope.selected_tags ? scope.selected_tags.reduce( (acc, val) => (acc + val.name), '') : '';
				}, function (n, o) {
					if(n != o){
						scope.show_tags_list = scope.hide_btn = !!scope.tags.length && scope.hide_btn === true;
					}
				});
				
				TagsService.findAll().then(function (response) {
					if(!isUndefinedOrNull(scope.selected_tags) && scope.selected_tags.length > 0){
						scope.tags = response.filter(item => scope.selected_tags.find(tag => tag.id == item.id) === undefined);
					}else{
						scope.tags = response;
					}

					TagPropertySettingService.query({property_id: scope.propertyId, type: scope.propertyType}).then(function (response) {
						scope.selected_tags = response.data;

						scope.tags = scope.tags.filter(item => scope.selected_tags.find(tag => tag.id == item.id) === undefined);

						scope.tags_loaded = true;
					});
				});

				scope.addTags = function(){
					scope.hide_btn = true;
					scope.show_tags_list = true;
				};

				scope.removeTag = function(tag){

					if(tag.deleting != true) {
						tag.deleting = true;

						TagPropertySettingService.deleteTagProperty({property_id: scope.propertyId, type: scope.propertyType, tag_id: tag.id}).then(function () {
							TagPropertySettingService.query({type: scope.propertyType, property_id: scope.propertyId}).then(function (response) {
								scope.selected_tags = response.data;
								delete tag.deleting;
							});
						});

						scope.tags.push(tag);
					}

				};

				scope.tagSelected = function tagSelected(item) {
					scope.selected_tags.push(item);
					scope.tags = scope.tags.filter(x => x != item);
					scope.directive_data.selected_tag = undefined;

					TagPropertySettingService.save({
						property_ids: [scope.propertyId],
						type: scope.propertyType,
						tag_ids: [item.id]
					});
				}
			}
		}
	}]);

	app.directive("scrollBottom", function ($timeout) {
		return {
			link: function (scope, element, attr) {

				scope.enabledTermsCheckbox = false;

				scope.$watch(attr.ngModel, function (n, o) {
					$(element).scrollTop(0);

					$timeout(function(){
						scope.$apply();
						$(element).trigger('scroll');
					});
				});

				$(element).on('scroll', function () {

					if ($(this).find('.terms-txt').outerHeight() < $(this).scrollTop() + $(this).height() + 20) {
						scope.enabledTermsCheckbox = true;
						scope.$apply();
					}

				});
			}
		}
	});

	app.directive('elementReady', function ($timeout, $rootScope) {
		return {
			restrict: 'A',
			link(scope, element, attrs) {
				$timeout(() => {
					element.ready(() => {
						scope.$apply(() => {
							$rootScope.$broadcast(`${attrs.elementReady}:ready`);
						});
					});
				});
			}
		}
	});

	app.directive('parseToDollars', ['$rootScope', function ($rootScope) {
		return {
			require: 'ngModel',
			restrict: 'A',
			link: function (scope, elem, attrs, ngModelCtrl) {

				//format text from the code (model to view)
				ngModelCtrl.$formatters.push(function (value) {
					let parse_to_dollars = scope.$eval(attrs.parseToDollars);
					let ret_value = value;

					if (value) {
						ret_value = $rootScope.parseToDollars(value + '', 0, 'add', 0, parse_to_dollars, 1, true);
					}

					return ret_value;
				});

				//format text from the user (view to model)
				ngModelCtrl.$parsers.push(function (value) {
					value = value + '';
					return value.replace(/[^0-9.]/g, "");
				});

				//trigger formatter on blur
				elem.bind('blur', function () {
					let parse_to_dollars = scope.$eval(attrs.parseToDollars);
					ngModelCtrl.$setViewValue($rootScope.parseToDollars(elem.val() + '', 0, 'add', 0, parse_to_dollars, 1, true));
					ngModelCtrl.$render();
				});

				//trigger formatter on budget-input directive value change
				scope.$watch(function () {
					return scope.$eval(attrs.parseToDollars);
				}, function (n, o) {
					if (n !== o) {
						let parse_to_dollars = scope.$eval(attrs.parseToDollars);
						ngModelCtrl.$setViewValue($rootScope.parseToDollars(elem.val() + '', 0, 'add', 0, parse_to_dollars, 1, true));
						ngModelCtrl.$render();
					}
				})
			}
		}

	}]);

	app.directive('adjustCurrencyValue', ['$rootScope', function ($rootScope) {
		return {
			require: 'ngModel',
			restrict: 'A',
			link: function (scope, elem, attrs, ngModelCtrl) {

				//format text from the code (model to view)
				ngModelCtrl.$formatters.push(function (value) {
					let ret_value = value;

					if (value) {
						ret_value = $rootScope.parseToDollars(value + '', 0, 'add', 0, true, 1, true);
					}

					return ret_value;
				});

				//format text from the user (view to model)
				ngModelCtrl.$parsers.push(function (value) {
					value = value + '';
					return value.replace(/[^0-9.]/g, "");
				});

				//trigger formatter on blur
				elem.bind('blur', function () {
					ngModelCtrl.$setViewValue($rootScope.parseToDollars(elem.val() + '', 0, 'add', 0, true, 1, true));
					ngModelCtrl.$render();
				});
			}
		}

	}]);

	app.directive('watchFirstLast',['$timeout', '$rootScope', '$parse', function($timeout, $rootScope, $parse){
		return {
			restrict: 'A',
			link: function(scope, element, attrs, ctrl){
				let watch_first_last_function = scope.$eval(attrs.watchFirstLast);

				watch_first_last_function(true, true);
				$timeout(() => {
					element.ready(() => {
						if(scope.$first){
							scope.$apply(() => {
								if(watch_first_last_function){
									watch_first_last_function(true, false)
								}
							});
						}

						if(scope.$last){
							scope.$apply(() => {
								if(watch_first_last_function){
									watch_first_last_function(false, true)
								}
							});
						}
					});
				});
			}
		}
	}]);

})();
