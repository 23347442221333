'use strict';

angular.module('clickbooth-common').service('errorModal', ['$rootScope', '$modal', function ($rootScope, $modal) {
    var scope = $rootScope.$new(true);
    var modal = $modal({
        scope: scope,
        templateUrl: template('modals/error-modal'),
        backdrop: 'static',
        show: false
    });
    return {
        show: function show(options, proceed) {
            scope.proceed = proceed;
            scope.modalOptions = options;
            modal.$promise.then(modal.show);
        }
    };
}]);