'use strict';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TabsGroupController =

/* @ngInject */
function TabsGroupController(tabs_id_prefix) {
    _classCallCheck(this, TabsGroupController);

    this.tabs_object_base = {

        addTab: function addTab(tab, index) {
            var clear_filters_storage_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            this.addTabFunctions(tab);

            if (!index) {
                index = this.list.length + 1;
            }

            if (tab.selected) {
                this.deselectAllTabs();
            }

            var select_tab = true;
            if (typeof tab.selected !== 'undefined') {
                select_tab = tab.selected;
            }
            tab.selected = undefined;

            tab.controller.addTab = this.addTab;

            this.list.splice(index, 0, tab);

            this.setTabId(tab, tab.id);

            if (clear_filters_storage_data) {
                tab.controller.clearFiltersStorageData(tab.id);
            }
            tab.controller.setFiltersId(tab.id);

            if (select_tab) {
                this.selectTab(tab);
            }

            this.created_tabs_count++;
        },

        deselectAllTabs: function deselectAllTabs() {
            for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].selected == true) {
                    this.list[i].selected = false;
                    this.triggerTabEvent(this.list[i], 'onDeselectTab');
                }
            }
        },

        selectTab: function selectTab(selected_tab) {
            recalcTableFloatingButton(selected_tab.id);
            this.deselectAllTabs();
            selected_tab.selected = true;

            this.triggerTabEvent(selected_tab, 'onSelectTab');
        },

        selectTabByIndex: function selectTabByIndex(index) {
            this.deselectAllTabs();
            this.list[index].selected = true;
        },

        closeTab: function closeTab(selected_tab) {
            var clear_filters_storage_data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            if (clear_filters_storage_data) {
                selected_tab.controller.clearFiltersStorageData(selected_tab.id);
            }

            var selected_tab_index = this.list.indexOf(selected_tab);
            var closing_tab_is_selected = selected_tab.selected;
            var previous_element = this.list[(selected_tab_index + this.list.length + 1) % this.list.length];

            this.list.splice(selected_tab_index, 1);

            if (closing_tab_is_selected && this.list.length > 0) {
                previous_element.selected = true;
                recalcTableFloatingButton(previous_element.id);
            }
        },

        closeTabByIndex: function closeTabByIndex(index) {
            var selected_tab = this.list[index];
            selected_tab.controller.clearFiltersStorageData(selected_tab.id);
            var selected_tab_index = this.list.indexOf(selected_tab);
            var previous_element = this.list[(selected_tab_index + this.list.length) % this.list.length];

            this.list.splice(selected_tab_index, 1);

            if (this.list.length > 0) {
                previous_element.selected = true;
            }
        },

        triggerTabEvent: function triggerTabEvent(tab, event, params) {
            if (tab.controller && tab.controller[event]) {
                tab.controller[event](params);
            }
        },

        getTabIndex: function getTabIndex(tab) {
            return this.list.indexOf(tab);
        },

        setTabsIdPrefix: function setTabsIdPrefix(prefix) {
            this.tabs_id_prefix = prefix;
        },

        setTabId: function setTabId(tab, tab_id) {
            var tabs_prefix = this.tabs_id_prefix ? this.tabs_id_prefix : '';
            if (!tab_id || tab_id && tab_id.length == 0) {
                tab.id = tabs_prefix + 'tab' + this.created_tabs_count;
            } else {
                tab.id = tabs_prefix + tab_id;
            }
        },

        addTabFunctions: function addTabFunctions(tab) {
            var tabs_this = this;
            angular.extend(tab, {
                select: function select() {
                    tabs_this.selectTab(tab);
                },
                close: function close() {
                    tabs_this.closeTab(tab);
                },
                index: function index() {
                    return tabs_this.getTabIndex(tab);
                },
                getId: function getId() {
                    return tab.id;
                },
                setId: function setId(tab_id) {
                    tabs_this.setTabId(tab, tab_id);
                }
            });
        },
        selectedTabIndex: function selectedTabIndex() {
            for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].selected) {
                    return this.list.indexOf(this.list[i]);
                }
            }
        },

        selectedTab: function selectedTab() {
            for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].selected) {
                    return this.list[i];
                }
            }
        }
    };

    this.tabs = $.extend({}, this.tabs_object_base);
    delete this.tabs_object_base;
    this.tabs.list = [];

    this.tabs.tabs_id_prefix = '';
    if (tabs_id_prefix) {
        this.tabs.tabs_id_prefix = tabs_id_prefix;
    }

    this.tabs.created_tabs_count = 0;
};

var LeapTabsController =

/* @ngInject */
function LeapTabsController($scope, $rootScope) {
    _classCallCheck(this, LeapTabsController);

    $scope.openLinkModal = $rootScope.openLinkModal;
    $scope.offerCard = $rootScope.offerCard;
    $scope.mediumDateFormat = $rootScope.mediumDateFormat;
    $scope.timezone = $rootScope.timezone;
    $scope.template = $rootScope.template;

    this.tabs_controller = $scope.tabs_controller;
};

var app = angular.module('clickbooth-common');

app.directive('leapTabs', function () {
    return {
        require: ['tabs'],
        restrict: 'E',
        scope: {
            tabs: '=?',
            tabsGroup: '=?'
        },
        transclude: true,
        templateUrl: template('tabs/template'),
        controllerAs: 'tabs_ctrl',
        controller: LeapTabsController
    };
});

app.directive('leapHtmlTemplate', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        replace: true,
        link: function link(scope, ele, attrs) {
            var html_template_watch = scope.$watch(attrs.ddHtmlTemplate, function (html) {
                ele.html(html);
                $compile(ele.contents())(scope);
            });

            scope.$on('$destroy', function () {
                html_template_watch();
            });
        }
    };
}]);

app.service('TabsService', ['$injector', function ($injector) {
    var tabs_groups = [];
    function tabsGroup(tabs_group_name) {
        if (tabs_group_name in tabs_groups) {
            return tabs_groups[tabs_group_name];
        }
        tabs_groups[tabs_group_name] = $injector.instantiate(TabsGroupController, { tabs_id_prefix: tabs_group_name + "_" });
        return tabs_groups[tabs_group_name];
    }

    function removeTabsGroup(tabs_group_name) {
        if (tabs_group_name in tabs_groups) delete tabs_groups[tabs_group_name];
    }

    function initTabController(tab_class, tabs_group_name, constructor_parameters) {
        var tab_ctrl = $injector.instantiate(tab_class, constructor_parameters);
        tab_ctrl.tabs_group_name = tabs_group_name;

        tab_ctrl.tabs_service = this;
        tab_ctrl.tabs_group = this.tabsGroup(tabs_group_name);

        if (tab_ctrl.initTab) {
            tab_ctrl.initTab();
        }
        return tab_ctrl;
    }

    return {
        tabsGroup: tabsGroup,
        initTabController: initTabController,
        removeTabsGroup: removeTabsGroup
    };
}]);