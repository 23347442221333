'use strict';

angular.module('clickbooth-common').service('importantSecurityModal', ['$rootScope', '$modal', '$state', 'config', function ($rootScope, $modal, $state, config) {
    var scope = $rootScope.$new(true);
    var modal = $modal({
        scope: scope,
        controllerAs: 'ctrl',
        controller: ['$scope', '$state', 'config', function ($scope, $state, config) {

            $scope.redirect = function () {
                if (config.user.user_type == 'admin') {
                    $state.go('users-admin', { modal: true });
                } else if (config.user.user_type == 'affiliate') {
                    $state.go('account.two-factor-authentication', { modal: true });
                } else if (config.user.user_type == 'advertiser') {
                    $state.go('account.two-factor-authentication', { modal: true });
                }
            };

            $scope.closeModal = function (hide) {
                hide();
                if (!isUndefinedOrNull(window.displayModals)) {
                    window.displayModals();
                }
                if (typeof scope.closeCallback == 'function') {
                    scope.closeCallback();
                }
            };
        }],
        templateUrl: template('modals/important-security-modal'),
        backdrop: 'static',
        show: false
    });

    return {
        show: function show(options, closeCallback) {
            scope.modalOptions = options;
            scope.closeCallback = closeCallback;
            modal.$promise.then(modal.show);
        },
        hide: function hide() {
            modal.hide();
        }
    };
}]);