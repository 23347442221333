import {MMRCaseDetailsModalController} from "./MMRCaseDetailsModalController";

angular.module('clickbooth-common')

    .service('MMRCases', ['config', 'Resource', function(config, Resource) {

        //different naming from advertiser and admin
        var api = '';
        var base_url = '';
        if(isUndefinedOrNull(config.apiBase)) {
            base_url = config.api + '/';
            api = config.api + '/mmr_cases';
        } else {
            base_url = config.apiBase;
            api = config.apiBase + 'mmr_cases';
        }

        let mmrCases = new Resource({
            url: api,
            base_url: base_url
        });

        mmrCases.getData = function (params){
            return this.request({
                url: this.options.base_url + 'mmr_cases',
                method: "GET",
                params: params
            });
        }

        mmrCases.getCreatives = function (mmr_case_id) {
            return this.request({
                url: this.options.url + "/creatives/" + mmr_case_id,
                method: "GET"
            });
        }

        mmrCases.getMMRCreativeImage = function (creative, preview) {
            let url = this.options.base_url + 'file/mmr_creative/' + creative.id;
            let file_name = creative.filename
            if (preview == 'preview') {
                file_name = creative.filename.replace('.pdf', '.jpg')
            }
            let query = {
                name: encodeURIComponent(file_name)
            };

            return buildUrl(url, query);
        }

        mmrCases.getTempMMRCreativeImage = function (creative, preview) {
            let url = this.options.base_url + 'file/mmr_creative/temp/' + config.user.id + '/' + creative.campaign_id;
            let file_name = creative.file.name
            if (preview == 'preview') {
                file_name = creative.file.name.replace('.pdf', '.jpg')
            }
            let query = {
                creative_id: creative.id,
                name: encodeURIComponent(file_name),
                download: creative.file.name
            };

            return buildUrl(url, query);
        };

        mmrCases.saveMMRCase = function (mmr_case){
            return this.request({
                url: this.options.url + '/save_mmr_case',
                method: 'POST',
                data: mmr_case
            });
        }

        mmrCases.updateInternalNotes =  function (params){
            return this.request({
                url: this.options.url + '/save_internal_notes',
                method: 'POST',
                data: params
            })
        }

        return mmrCases;
    }])
    .factory('MMRCaseDetailsModal', function ($modal, $rootScope, $controller) {
        let scope = $rootScope.$new(true);

        scope.controller = $controller('MMRCaseDetailsModalController', {$scope: scope});

        let modal = $modal({
            scope: scope,
            backdrop: 'static',
            templateUrl: template('mmr/mmr-case-details-modal'),
            show: false
        });

        return {
            show(mmr_case, user, cb, is_advertiser = false) {
                scope.controller.init(mmr_case, user, cb, is_advertiser);
                modal.$promise.then(modal.show);
            },

            hide() {
                scope.controller.close(modal.hide);
            }
        }


    })

    .controller('MMRCaseDetailsModalController', MMRCaseDetailsModalController)
