'use strict';

angular.module('clickbooth-common').service('editValueModal', ['$rootScope', '$modal', function ($rootScope, $modal) {
    var scope = $rootScope.$new(true);
    var modal = $modal({
        scope: scope,
        templateUrl: template('modals/edit-value-modal'),
        backdrop: 'static',
        show: false
    });

    return {
        show: function show(options) {
            scope.modalOptions = options;
            modal.$promise.then(modal.show);
        },
        hide: function hide() {
            modal.hide();
        }
    };
}]);