
angular.module('clickbooth-common')
	// .directive('resizableColumnsId', ['throttle', function (throttle) {
	// 	return {
	// 		restrict: 'AEC',
	// 		link: function (scope, elem) {
	// 			elem.addClass('resize');
    //
	// 			scope.$evalAsync(function () {
	// 				elem.resizableColumns({store: store});
	// 				waited = true
	// 			});
    //
	// 			var waited = false;
    //
	// 			var resize = throttle(function () {
	// 				elem.trigger('resize');
	// 			});
    //
	// 			var watcher = scope.$watch(function () {
	// 				waited && resize();
	// 			});
    //
	// 			scope.$on('$destroy', function () {
	// 				var resizableColumns = elem.data('resizableColumns');
    //
	// 				if(typeof resizableColumns != 'undefined') {
	// 					resizableColumns.destroy();
	// 					watcher();
	// 				}
	// 			});
	// 		}
	// 	};
	// }])
	.directive('floatingTable', ['$compile', 'debounce', function ($compile, debounce) {
		return {
			restrict: 'A',
			link: function (scope, elem) {
				if (!elem.is('table')) {
					throw new Error('Must apply this directive to table only!');
				}
				// var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
				//
				// if (is_firefox) {
				// 	scope.$evalAsync(function () {
				// 		var table = elem;
				// 		var $window = $(window);
				// 		var frame = table.parents('.table-responsive');
				//
				//
				// 		var h = (function () {
				// 			var h = $('header').outerHeight() || 0;
				// 			h += $('.page-tabs').outerHeight() || 0;
				// 			h += $('.notification-bar').outerHeight() || 0;
				// 			return h;
				// 		})();
				//
				// 		function scroll () {
				// 			var thead = table.find('thead');
				//
				// 			var pos = table.offset();
				//
				// 			var naturalOffset = pos.top - h;
				// 			var scrollTop = $window.scrollTop();
				// 			var newScroll = scrollTop - naturalOffset;
				//
				// 			if (newScroll < 0) {
				// 				newScroll = 0;
				// 			}
				//
				// 			thead.css({
				// 				position: 'relative',
				// 				top: newScroll,
				// 				zIndex: 1
				// 			});
				//
				// 			thead[newScroll == 0 ? 'removeClass' : 'addClass']('thead-insane');
				// 		}
				//
				// 		frame.on('scroll', scroll);
				// 		$window.on('scroll', scroll);
				//
				// 		scope.$on('$destroy', function () {
				// 			frame.off('scroll', scroll);
				// 			$window.off('scroll', scroll);
				// 		});
				// 	});
				// }
				//
				// else {
					var windowWidth = $(window).width();
                    //floatThead-track
                    elem.on("floatThead", function(e, isFloated, $floatContainer){
                        if(isFloated){
                            $('body, .floatThead-track').addClass("floated"); // $table

                        } else {
                            $('body, .floatThead-track').removeClass("floated");
                        }
                    });

					scope.$evalAsync(function () {
						var started = false;
						scope.$watch(function () {
							return elem.find('tr').length;
						}, function (n, o) {
							if (n > 0 && !started && windowWidth > 687) {
								started = true;
								elem.floatThead({
									scrollingTop: function () {
										var h = $('header, .ui-header').outerHeight() || 0;
										h += $('.page-tabs').outerHeight() || 0;
										h += $('.notification-bar').outerHeight() || 0;
										h += $('.customize-filters-toolbar').outerHeight() || 0;
										return h;
									},

									floatTableClass: 'thead-insane'
								});
							}
							else {
								elem.floatThead('reflow');
							}
						});
					});

					$(document).on('mouseup', function () {
						elem.floatThead('reflow');
					});
				// }
				//var timeout_id=false;

                //table floating column
                function floatingColumn() {
                    elem.find('tbody > tr').each(function (i) {
                        $('.fixed-column tbody > tr:eq('+i+')').height($(this).outerHeight());
                    });
                    elem.find('thead > tr').each(function (i) {
                        $('.fixed-column thead > tr:eq('+i+')').height($(this).outerHeight());
                    });
                }
                if(elem.is( "[floating-gear-col]" )){
                    var watches = [elem.attr('floating-gear-col')];
                    for (var x in watches) {
                        scope.$watch(watches[x], function(changed,old) {
                            if(!changed && old){
                                setTimeout(floatingColumn,100);
                            }
                        });
                    }

                    var debounced_fix_table = debounce(floatingColumn, 100);
                    $(window).on('resize', debounced_fix_table);

                    scope.$on('$destroy', function() {
                        $(window).off('resize', debounced_fix_table);
                    });
                }

			}
		};
	}])





// An AngularJS module for resizing table columns!
// forked from: https://github.com/Tympanix/angular-table-resize
.directive('resizeable', ['resizeStorage', '$injector', '$timeout', function(resizeStorage, $injector, $timeout) {

    var mode;

    var columns = null;
    var ctrlColumns = null;
    var handleColumns = null;
    var table = null;
    var container = null;
    var resizer = null;
    var isFirstDrag = true;

    var cache = null;

    function link(scope, element, attr) {
        if ($(window).width() < 767) {
            return;
        }

        // Set global reference to table
        table = element;

        // Set global reference to container
        container = scope.container ? $(scope.container) : $(table).parent();

        // Add css styling/properties to table
        $(table).addClass('resize');
        var usetimeout = table.attr('additional-table-resize');
        // Initialise handlers, bindings and modes
        // setTimeout(function () {
            initialiseAll(table, attr, scope, usetimeout);
            // window.dispatchEvent(new Event('resize'));
        // });

        // Bind utility functions to scope object
        bindUtilityFunctions(table, attr, scope)

        // Watch for mode changes and update all
        watchModeChange(table, attr, scope);
    }

    function bindUtilityFunctions(table, attr, scope) {
        if (scope.bind === undefined) return;
        scope.bind = {
            update: function() {
                cleanUpAll(table);
                initialiseAll(table, attr, scope);
            }
        }
    }

    function watchModeChange(table, attr, scope) {
        scope.$watch(function() {
            return scope.mode;
        }, function(/*newMode*/) {
            cleanUpAll(table);
            initialiseAll(table, attr, scope);
        });
    }

    function cleanUpAll(table) {
        isFirstDrag = true;
        deleteHandles(table);
    }

    function resetTable(table) {
        $(table).outerWidth('100%');

        var cache = [];

        $(table).find('th').each(function(item, i){
            "use strict";
            cache[$(i).attr('resize-th-id')] = $(i).outerWidth();
            $(i).width(cache[$(i).attr('resize-th-id')]+"px");
        });

        $(table).width('auto');

        ctrlColumns.each(function(index, column){
            var id = $(column).attr('resize-th-id');
            var cacheWidth = cache[id];
            $(column).css({width: cacheWidth});
        });

        saveColumnSizes();

        resizer.onTableReady();
    }

    function deleteHandles(table) {
        $(table).find('th').find('.handle').remove();
    }

    function initialiseAll(table, attr, scope, x = null) {
        // Get all column headers
        columns = $(table).find('th');

        mode = scope.mode;

        // Get the resizer object for the current mode
        var ResizeModel = getResizer(scope, attr);
        if (!ResizeModel) return;
        resizer = new ResizeModel(table, columns, container);

        // Load column sized from saved storage
        cache = resizeStorage.loadTableSizes(table, scope.mode)

        // Decide which columns should have a handler attached
        handleColumns = resizer.handles(columns);

        // Decide which columns are controlled and resized
        ctrlColumns = resizer.ctrlColumns;

        // Execute setup function for the given resizer mode
        resizer.setup();

        // Set column sizes from cache
        if(x){
            $timeout(() => {
                setColumnSizes(cache);
                window.dispatchEvent(new Event('resize'));
            }, 100)
        }else {
            setColumnSizes(cache);
        }

        // Initialise all handlers for every column
        handleColumns.each(function(index, column) {
            initHandle(table, column);
        })
        $('<div>', {
            class: 'tracker',
            id: 'tracker_'+table.attr('resize-table-id')
        }).insertAfter( table );

    }

    function setColumnSizes(cache) {
        if (! cache || angular.equals(cache,{})) {
            resetTable(table);
            return;
        }

        $(table).width('auto');

        ctrlColumns.each(function(index, column){
            var id = $(column).attr('resize-th-id');
            var cacheWidth = cache[id];
            $(column).css({width: cacheWidth});
        })

        resizer.onTableReady();
    }

    function initHandle(table, column) {
        // Prepend a new handle div to the column
        var handle = $('<div>', {
            class: 'handle'
        });
        $(column).prepend(handle);

        // Make handle as tall as the table
        //$(handle).height($(table).height())

        // Use the middleware to decide which columns this handle controls
        var controlledColumn = resizer.handleMiddleware(handle, column)

        // Bind mousedown, mousemove & mouseup events
        bindEventToHandle(table, handle, controlledColumn);
    }

    function bindEventToHandle(table, handle, column) {
        var trackerHandle = $('#tracker_'+table.attr('resize-table-id'));
        // This event starts the dragging
        $(handle).mousedown(function(event) {

            if (isFirstDrag) {
                resizer.onFirstDrag(column, handle);
                resizer.onTableReady();
                isFirstDrag = false;
            }

            var optional = {}
            if (resizer.intervene) {
                optional = resizer.intervene.selector(column);
                optional.column = optional;
                optional.orgWidth = $(optional).width();
            }

            optional.leftX = 236;
            if ($('.app-content.sidebar-closed').length > 0)  {
                optional.leftX = 100;
            }

            // Prevent text-selection, object dragging ect.
            event.preventDefault();

            // Change css styles for the handle
            $(handle).parent().addClass('active-chiza');
            // Show the resize cursor globally
            $('body').addClass('table-resize');
            // Get mouse and column origin measurements
            var orgX = event.clientX;
            var orgWidth = $(column).width();
            trackerHandle.addClass('active-handle').css({left:orgX - optional.leftX});

            // On every mouse move, calculate the new width
            $(window).mousemove(calculateWidthEvent(column, orgX, orgWidth, optional, trackerHandle))

            // Stop dragging as soon as the mouse is released
            $(window).one('mouseup', unbindEvent(handle,trackerHandle))

        })
    }

    function calculateWidthEvent(column, orgX, orgWidth, optional, trackerHandle) {
        return function(event) {
            // Get current mouse position
            var newX = event.clientX;

            // Use calculator function to calculate new width
            var diffX = newX - orgX;
            var newWidth = resizer.calculate(orgWidth, diffX);

            // Use restric function to abort potential restriction
            if (resizer.restrict(newWidth)) return;

            // Extra optional column
            if (resizer.intervene){
                var optWidth = resizer.intervene.calculator(optional.orgWidth, diffX);
                if (resizer.intervene.restrict(optWidth)) return;
                $(optional).width(optWidth)
            }

            // Set size
            $(column).width(newWidth);
            if(trackerHandle){
                trackerHandle.css({left:newX - optional.leftX})
            }
        }
    }

    function getResizer(scope, attr) {
        try {
            var mode = attr.mode ? scope.mode : 'BasicResizer';
            var Resizer = $injector.get(mode)
            return Resizer;
        } catch (e) {
            console.error("The resizer "+ scope.mode +" was not found");
            return null;
        }
    }


    function unbindEvent(handle,trackerHandle) {
        // Event called at end of drag
        return function( /*event*/ ) {
            $(handle).parent().removeClass('active-chiza');
            $(window).unbind('mousemove');
            $('body').removeClass('table-resize');
            setTimeout(()=>{
                resizer.onEndDrag();
                saveColumnSizes();
                trackerHandle.removeClass('active-handle');
            },100)
        }
    }

    function saveColumnSizes() {
        if (!cache) cache = {};
        $(columns).each(function(index, column) {
            var id = $(column).attr('resize-th-id');
            if (!id) return;
            cache[id] = resizer.saveAttr(column);
        })
        resizeStorage.saveTableSizes(table, mode, cache);
    }

    // Return this directive as a object literal
    return {
        restrict: 'A',
        link: link,
        scope: {
            mode: '=',
            bind: '=',
            container: '@'
        }
    };

}])

.service('resizeStorage', ['$window', function($window) {

    var prefix = "ngColumnResize";

    this.loadTableSizes = function(table, model) {
        var key = getStorageKey(table, model);
        var object = $window.localStorage.getItem(key);
        return JSON.parse(object);
    }

    this.saveTableSizes = function(table, model, sizes) {
        var key = getStorageKey(table, model);
        if (!key) return;
        if ( table.attr('resize-table-id') != 'no-cache-id') {
            var string = JSON.stringify(sizes);
            $window.localStorage.setItem(key, string)
        }
    }

    function getStorageKey(table, mode) {
        var id = table.attr('resize-table-id');
        if (!id) {
            console.error("Table has no id", table);
            return undefined;
        }
        return prefix + '.' + table.attr('resize-table-id') + '.' + mode;
    }

}])

.factory("ResizerModel", [function() {

    function ResizerModel(table, columns, container){
        this.minWidth = 25;

        this.table = table;
        this.columns = columns;
        this.container = container;

        this.handleColumns = this.handles();
        this.ctrlColumns = this.ctrlColumns();
    }

    ResizerModel.prototype.setup = function() {
        // Hide overflow by default
        $(this.container).css({
            overflowX: 'hidden'
        })
    }

    ResizerModel.prototype.onTableReady = function () {
        // Table is by default 100% width
        $(this.table).outerWidth('100%');
    };

    ResizerModel.prototype.handles = function () {
        // By default all columns should be assigned a handle
        return this.columns;
    };

    ResizerModel.prototype.ctrlColumns = function () {
        // By default all columns assigned a handle are resized
        return this.handleColumns;
    };

    ResizerModel.prototype.onFirstDrag = function () {
        // By default, set all columns to absolute widths
        $(this.ctrlColumns).each(function(index, column) {
            $(column).width($(column).width());
        })
    };

    ResizerModel.prototype.handleMiddleware = function (handle, column) {
        // By default, every handle controls the column it is placed in
        return column;
    };

    ResizerModel.prototype.restrict = function (newWidth) {
        // By default, the new width must not be smaller that min width
        return newWidth < this.minWidth;
    };

    ResizerModel.prototype.calculate = function (orgWidth, diffX) {
        // By default, simply add the width difference to the original
        return orgWidth + diffX;
    };

    ResizerModel.prototype.onEndDrag = function () {
        // By default, do nothing when dragging a column ends
        return;
    };

    ResizerModel.prototype.saveAttr = function (column) {
        return $(column).outerWidth();
    };

    return ResizerModel;
}])

.factory("BasicResizer", ["ResizerModel", function(ResizerModel) {

    function BasicResizer(table, columns, container) {
        // Call super constructor
        ResizerModel.call(this, table, columns, container)

        // All columns are controlled in basic mode
        this.ctrlColumns = this.columns;

        this.intervene = {
            selector: interveneSelector,
            calculator: interveneCalculator,
            restrict: interveneRestrict
        }
    }

    // Inherit by prototypal inheritance
    BasicResizer.prototype = Object.create(ResizerModel.prototype);

    function interveneSelector(column) {
        return $(column).next()
    }

    function interveneCalculator(orgWidth, diffX) {
        return orgWidth - diffX;
    }

    function interveneRestrict(newWidth){
        return newWidth < 25;
    }

    BasicResizer.prototype.setup = function() {
        // Hide overflow in mode fixed
        $(this.container).css({
            overflowX: 'hidden'
        })

        // First column is auto to compensate for 100% table width
        $(this.columns).first().css({
            width: 'auto'
        });
    };

    BasicResizer.prototype.handles = function() {
        // Mode fixed does not require handler on last column
        return $(this.columns).not(':last')
    };

    BasicResizer.prototype.onFirstDrag = function() {
        // Replace all column's width with absolute measurements
        $(this.columns).each(function(index, column) {
            $(column).width($(column).width());
        })
    };

    BasicResizer.prototype.onEndDrag = function () {
        // Calculates the percent width of each column
        var totWidth = $(this.table).outerWidth();

        var totPercent = 0;

        $(this.columns).each(function(index, column) {
            var colWidth = $(column).outerWidth();
            var percentWidth = colWidth / totWidth * 100 + '%';
            totPercent += (colWidth / totWidth * 100);
            $(column).css({ width: percentWidth });
        })

    };

    BasicResizer.prototype.saveAttr = function (column) {
        return $(column)[0].style.width;
    };

    // Return constructor
    return BasicResizer;

}])
.factory("FixedResizer", ["ResizerModel", function(ResizerModel) {

    function FixedResizer(table, columns, container) {
        // Call super constructor
        ResizerModel.call(this, table, columns, container)

        this.fixedColumn = $(table).find('th').first();
        this.bound = false;
    }

    // Inherit by prototypal inheritance
    FixedResizer.prototype = Object.create(ResizerModel.prototype);

    FixedResizer.prototype.setup = function() {
        // Hide overflow in mode fixed
        $(this.container).css({
            overflowX: 'hidden'
        })

        // First column is auto to compensate for 100% table width
        $(this.columns).first().css({
            width: 'auto'
        });
    };

    FixedResizer.prototype.handles = function() {
        // Mode fixed does not require handler on last column
        return $(this.columns).not(':last')
    };

    FixedResizer.prototype.ctrlColumns = function() {
        // In mode fixed, all but the first column should be resized
        return $(this.columns).not(':first');
    };

    FixedResizer.prototype.onFirstDrag = function() {
        // Replace each column's width with absolute measurements
        $(this.ctrlColumns).each(function(index, column) {
            $(column).width($(column).width());
        })
    };

    FixedResizer.prototype.handleMiddleware = function (handle, column) {
        // Fixed mode handles always controll next neightbour column
        return $(column).next();
    };

    FixedResizer.prototype.restrict = function (newWidth) {
        if (this.bound) {
            if (newWidth < this.bound) {
                $(this.fixedColumn).width('auto');
                this.bound = false;
                return false;
            } else {
                return true;
            }
        } else if (newWidth < this.minWidth) {
            return true;
        } else if ($(this.fixedColumn).width() <= this.minWidth) {
            this.bound = newWidth;
            $(this.fixedColumn).width(this.minWidth);
            return true;
        }
    };

    FixedResizer.prototype.calculate = function (orgWidth, diffX) {
        // Subtract difference - neightbour grows
        return orgWidth - diffX;
    };

    // Return constructor
    return FixedResizer;

}])
.factory("OverflowResizer", ["ResizerModel", function(ResizerModel) {

    function OverflowResizer(table, columns, container) {
        // Call super constructor
        ResizerModel.call(this, table, columns, container)
    }

    // Inherit by prototypal inheritance
    OverflowResizer.prototype = Object.create(ResizerModel.prototype);


    OverflowResizer.prototype.setup = function() {
        // Allow overflow in this mode
        $(this.container).css({
            overflow: 'auto'
        });
    };

    OverflowResizer.prototype.onTableReady = function() {
        // For mode overflow, make table as small as possible
        $(this.table).width(1);
        //var taable = $(this.table);
        /*setTimeout(function() {
            "use strict";
            $(taable).css("table-layout", "fixed");
        }, 100);*/
        $(this.table).css("table-layout", "fixed");
    };

    // Return constructor
    return OverflowResizer;

}]);

;
