'use strict';

(function () {
	var app = angular.module('clickbooth-common');

	app.value('angularRippleEnabled', true);

	function link(scope, element, attrs) {
		var x, y, size, offsets;

		element.attr('angular-ripple', '');

		element.on('click touchstart', function (e) {
			var ripple = this.querySelector('.angular-ripple');
			var eventType = e.type;
			// Ripple
			if (ripple === null) {
				// Create ripple
				ripple = document.createElement('span');
				ripple.classList.add('angular-ripple');

				// Prepend ripple to element
				this.insertBefore(ripple, this.firstChild);

				// Set ripple size
				if (!ripple.offsetHeight && !ripple.offsetWidth) {
					size = Math.max(element[0].offsetWidth, element[0].offsetHeight);
					ripple.style.width = size + 'px';
					ripple.style.height = size + 'px';
				}
			}

			// Remove animation effect
			ripple.classList.remove('animate');

			// get click coordinates by event type
			if (eventType === 'click') {
				x = e.pageX;
				y = e.pageY;
			} else if (eventType === 'touchstart') {
				x = e.changedTouches[0].pageX;
				y = e.changedTouches[0].pageY;
			}

			// set new ripple position by click or touch position
			function getPos(el) {
				for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent) {}
				return { left: lx, top: ly };
			};
			offsets = getPos(element[0]);
			ripple.style.left = x - offsets.left - size / 2 + 'px';
			ripple.style.top = y - offsets.top - size / 2 + 'px';

			// Add animation effect
			ripple.classList.add('animate');
		});
	}

	var k;
	var classes = ['btnPrimary', 'btnDefault', 'btnGray', 'ripple'];
	for (k in classes) {
		app.directive(classes[k], ['angularRippleEnabled', function (angularRippleEnabled) {
			if (angularRippleEnabled) {
				return {
					restrict: 'C',
					link: link
				};
			} else {
				return {};
			}
		}]);
	}
})();