(function() {
  var app, resizing;

  app = angular.module('interchange', []);

  resizing = null;

  $(window).resize(function() {
    return resizing = setTimeout(function() {
      return $(document).trigger('redraw');
    }, 100);
  });

  app.run([
    '$rootScope', function(scope) {
      var event, i, len, ref, results;
      ref = ['$routeChangeSuccess', '$routeChangeError', '$locationChangeSuccess', '$locationChangeError'];
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        event = ref[i];
        results.push(scope.$on(event, function() {
          return scope.$evalAsync(function() {
            return $(document).trigger('redraw');
          });
        }));
      }
      return results;
    }
  ]);

  app.provider('$interchangeProvider', function() {
    return {
      $get: function() {
        return {
          sm: 768,
          md: 992,
          lg: 1200
        };
      }
    };
  });

  app.directive('interchange', function($http, $q, $compile, $interchangeProvider, $templateCache) {
    return {
      restrict: 'A',
      link: function(scope, elem, attrs) {
        var attribute, fetchTemplate, oldTemplate, parse;
        attribute = '';
        parse = function() {
          var options, prop, viewport, width, windowWidth;
          options = scope.$eval(attrs.interchange);
          windowWidth = $(window).width();
          prop = 'xs';
          for (viewport in $interchangeProvider) {
            width = $interchangeProvider[viewport];
            if (windowWidth >= width && options[viewport]) {
              prop = viewport;
            }
          }
          if (options[prop]) {
            return fetchTemplate(options[prop]);
          }
        };
        attrs.$observe('interchange', parse);
        $(document).on('redraw', parse);
        oldTemplate = '';
        return fetchTemplate = function(temp) {
          if (temp === oldTemplate) {
            return;
          }
          return $q(function(resolve) {
            if ($templateCache.get(temp)) {
              return resolve($templateCache.get(temp));
            } else {
              return $http.get(temp).success(resolve);
            }
          }).then(function(data) {
            oldTemplate = temp;
            $templateCache.put(temp, data);
            elem.children().each(function() {
              return $(this).remove();
            });
            elem.append($compile(data)(scope));
            return $(document).trigger('redraw');
          });
        };
      }
    };
  });

}).call(this);
