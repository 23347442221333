'use strict';

angular.module('clickbooth-common').run(['$rootScope', function ($rootScope) {
	$rootScope.$on("$stateChangeSuccess", function (event, curr) {
		var stateName = curr.name;
		setTimeout(function () {

			function normalize(s) {
				if (s.indexOf('(')) {
					return s.replace(/\([\w\W]*?\)/g, '');
				}
				return s;
			}

			$("[ui-sref], [data-ui-sref]").each(function () {
				var elem = $(this);
				var parent = elem.parent();
				var linkState = normalize(elem.attr('ui-sref') || elem.attr('data-ui-sref'));

				if (elem.attr('ignore') == 'true') {
					return;
				}

				if (parent.is('li')) {
					parent.removeClass('active');
				} else {
					elem.removeClass('active');
				}
			});

			$("[ui-sref], [data-ui-sref]").each(function () {
				var elem = $(this);
				var linkState = normalize(elem.attr('ui-sref') || elem.attr('data-ui-sref'));

				if (elem.attr('ignore') == 'true') {
					return;
				}

				if (linkState == stateName) {
					var parent = elem.parent();

					if (parent.is('li')) {
						parent.addClass('active');
					} else {
						elem.addClass('active');
					}
				}
			});
		}, 0);
	});
}]);